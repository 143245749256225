import React, { useEffect, useCallback, useRef } from "react";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { LuPlusCircle } from "react-icons/lu";
import { FiMinusCircle } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useState } from "react";
import { SlArrowDown } from "react-icons/sl";
import { SlArrowUp } from "react-icons/sl";
import axiosInstance, { Razorpay_Key } from "../../Services/axios";
import { IoIosArrowForward } from "react-icons/io";
import { MdWork } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { MdOutlineCheckCircle } from "react-icons/md";
import { MdOutlineRadioButtonUnchecked } from "react-icons/md";
import defaultimg from "../../Assets/Images/default-placeholder.png";
import useRazorpay from "react-razorpay";
import { useUser } from "../../Components/UserContext";
import { getDatabase, ref, onValue, Database } from "firebase/database";
import Lottie from "lottie-react";
import SuccessLotie from "./../../Assets/lottieFiles/success.json";
import defaultproductimg from "../../Assets/Images/default-placeholder.png";
import { CgCloseO } from "react-icons/cg";
import { toast } from "react-toastify";
import { FaRegSquarePlus } from "react-icons/fa6";
import ReactLoading from "react-loading";
import ProductCard from "../../Components/product card/ProductCard";
import DeliveryTime_Slot from "../../Components/DeliveryTImeslot/DelivrySlot";
import { CiCalendar } from "react-icons/ci";
import { BsClock } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import { FaRupeeSign } from "react-icons/fa";
import { FaShippingFast } from "react-icons/fa";
import { FaArrowRotateRight } from "react-icons/fa6";
// Menu
import vegIcon from "../../Assets/Images/vegpngoutput.png"
import eggIcon from "../../Assets/Images/eggpngoutput.png"
import nonvegIcon from "../../Assets/Images/nomvegoutput.png"

// Addon 
import { IoMdClose } from "react-icons/io";
import { MdOutlineCheckBox } from "react-icons/md";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";

const ReviewPay = () => {

  const [Razorpay] = useRazorpay();
  const currentUser = useUser(); // Access user context
  const paymentSuccessModalRef = useRef(null);
  const orderSuccessModalRef = useRef(null);
  const locationModalRef = useRef(null);
  const cancelOrderModalRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { merchantId, newcartId, flow, offerCartId, inCartId, flowtype, fromPage, offerLoyaltyPoints } = location.state || {};
  const [showContent, setShowContent] = useState(false);
  const [productDetail, setProductDetail] = useState([]);
  const [productlist, setProductlist] = useState([]);
  const [shipingtype, setShipingtype] = useState("");
  const [userLocation, setUserLocation] = useState([]);
  const [confirmlocation, setConfirmlocation] = useState();
  const [userlatlong, setUserlatlong] = useState([]);
  const [paymenttype, setPaymenttype] = useState([]);
  const [selectpaytype, setSelectPaytype] = useState(1);
  const [gststatus, setGststatus] = useState(true);
  const [deliverylocation, setDeliverylocation] = useState([]);
  const [consulation, setConsulation] = useState("");
  const [deleteitem, setDeleteitem] = useState();
  const [animationSrc, setAnimationSrc] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cartId, setCartId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [pageLoaded, setPageLoaded] = useState(false);
  const [buyproduct, setBuyproduct] = useState([]);
  const [getproduct, setGetproduct] = useState([]);
  const [offerDetails, setOfferDetails] = useState();
  const [offertype, setOffertype] = useState();
  const [userId, setUserId] = useState();
  const [paymentOrderId, setPaymentOrderId] = useState();
  const token = localStorage.getItem("token");
  const [initiateTime, setInitiateTime] = useState("");
  const [loyaltyOfferid, setLoyaltyOfferid] = useState('')
  const [locationBtnloder, setLocationBtnloder] = useState(true);
  const [slotTime, setSlotTime] = useState('')
  const [deliveryDate, setDeliveryDate] = useState('')
  const [deliveryModelStatus, setDeliveryModelStatus] = useState(false)
  const [cardStatus, setCardStatus] = useState();
  const [cardType, setcardType] = useState();
  const [cardId, setcardId] = useState();
  const [showaddons, setShowaddons] = useState(false);

  const addonModalRef = useRef(null);
  const addonEditeModalRef = useRef(null);

  const [productid, setProductid] = useState('')
  const [productCost, setProductCost] = useState('')
  const [addonsCost, setAddonsCost] = useState('')
  const [selectedAddons, setSelectedAddons] = useState('')
  const [productCount, setProductCount] = useState(0)
  const [addonModel, setAddonModel] = useState(false);
  const [razorpayOrderId, setRazorpayOrderId] = useState("");
  const [deliverystaffAccept, setDeliverystaffAccept] = useState("0")
  const [orderId, setOrderId] = useState("");
  const [pilotAcceptStatus, setPilotAcceptStatus] = useState("0")
  const [deliverypartnerDetails, setDeliverypartnerDetailse] = useState("")
  const [instantDelivery, setInstantDelivery] = useState(false)
  const [timeChangeType, setTimeChangeType] = useState(false)
  const [selectItem, setSelectItem] = useState('')
  const [showDatePickerModal, setDatePickerModal] = useState(false);
  const [isDatePickerOffCanvasOpen, setDatePickerOffCanvasOpen] = useState(false);

  const flowtypeValue = flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : flowtype;


  useEffect(() => {

    const fetchData = async () => {
      let productCart = localStorage.getItem("productCart") ? JSON.parse(localStorage.getItem("productCart")) : "";
      setCartId(productCart?.cartId ? productCart?.cartId : "");
      setVendorId(productCart?.vendorId ? productCart?.vendorId : "");
      setShipingtype(productCart?.shipping_type ? productCart?.shipping_type : "");
      setLoyaltyOfferid(productCart?.loyaltyDetail?.offer_id ? productCart?.loyaltyDetail?.offer_id : "")

      let appliedCard = JSON.parse(localStorage.getItem("appliedCard"));
      setCardStatus(appliedCard?.cardStatus ? appliedCard?.cardStatus : "");
      setcardType(appliedCard?.cardType ? appliedCard?.cardType : "");
      setcardId(appliedCard?.cardId ? appliedCard?.cardId : "");


    };
    fetchData();

  }, []);

  useEffect(() => {
    if (flowtype) {
      let EditCart = localStorage.getItem("EditOrder_Detail") ? JSON.parse(localStorage.getItem("EditOrder_Detail")) : "";
      setCartId(EditCart?.orderid ? EditCart?.orderid : "");
      setShipingtype(EditCart?.shippingtype ? EditCart?.shippingtype : "");
      setVendorId(EditCart?.vendorId ? EditCart?.vendorId : "");
    }
  }, [flowtype]);
  useEffect(() => {
    const fetchData = async () => {
      if (cartId != "" && String(cartId).length > 0) {
        getselectProductDetail();

      }
    };
    fetchData();
    let initiateTimes = formatDateToCustomFormatWithTimeZone(
      new Date(),
      "Asia/Kolkata"
    );
    setInitiateTime(initiateTimes);

  }, [cartId, flow, offerCartId]);

  useEffect(() => {
    if (!currentUser) return; // Check if user is not logged in
    const database = getDatabase();
    const orderRef = ref(database, `/payments/product_order/${userId}`);
    // const orderRef = ref(database, `/payments/product_order/23U00002`);
    const unsubscribe = onValue(orderRef, (snapshot) => {
      const data = snapshot.val();

      if (data.status === 2 && data.order_id === paymentOrderId) {

        const productOrderId = data.product_order_id;
        openPaymentSuccessModal();
        if (productOrderId) {
          const productOrderId = data.product_order_id;
          openPaymentSuccessModal();
          if (productOrderId) {
            setOrderId(productOrderId);
            if (productOrderId) {
              AssignOrder(productOrderId);
            }
            setPilotAcceptStatus(1);
          }
        }
      }
      // if (data.status === 3 && data.order_id === paymentOrderId) {

      // }
    });
    return () => unsubscribe(); // Detach listener on cleanup
  }, [paymentOrderId]);

  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await fetch(
          "https://lottie.host/24705db4-5a9c-4d93-9c1b-217adf1bf628/z8KgTNPh81.json"
        );
        const animationData = await response.json();
        setAnimationSrc(animationData);
      } catch (error) {
        console.error("Error fetching animation data:", error);
      }
    };

    fetchAnimationData();
  }, []);
  console.log("productDetail", productDetail);


  useEffect(() => {
    const database = getDatabase();
    if (razorpayOrderId && pilotAcceptStatus != '2') {
      const orderRef = ref(database, `/payments/product_order/${productDetail?.user_id}`);
      const unsubscribe = onValue(orderRef, (snapshot) => {
        if (snapshot.val() != null) {
          const productOrderId = snapshot.val().product_order_id
          setOrderId(productOrderId)
          if (snapshot.val().order_id == razorpayOrderId) {
            if (productOrderId) {
              AssignOrder(productOrderId);
            }
            setDeliverystaffAccept('1');
          } else if (snapshot.val().status == 3) {
            setPilotAcceptStatus(2);
          }
          else {
          }
        }
      });
    }
  }, [razorpayOrderId]);

  useEffect(() => {

    const database = getDatabase();
    if (orderId) {
      const orderRef = ref(database, `/instant_delivery/${orderId}`);
      const unsubscribe = onValue(orderRef, (snapshot) => {


        if (snapshot.val() != null) {


          if (snapshot.val() == 3) {
            setDeliverystaffAccept('1');
            if (orderId) {
              AssignOrder(orderId);
            }
          } else if (snapshot.val() == 2) {
            deliveryStaffDetails()
            setDeliverystaffAccept('2');
          } else {

          }
        }
      });

      return () => unsubscribe();

    }
  }, [orderId]);

  const AssignOrder = (productOrderId) => {
    let data = {
      order_id: productOrderId,
      vendor_id: vendorId,
    };


    axiosInstance.post("/user/products/assign_order", data)
      .then((res) => {


        if (res.data.status == "3") {
          setDeliverystaffAccept("3")
          // toast(res.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: 'text-danger toast-style-1' });

        }

        // setDeliverystaffAccept(res?.status)
        // setDeliverystaffAccept("3")

        if (res.status == 200) {

          // status
        }
      })
      .catch((err) => {
        if (err.response.status == 500 || err.response.status == 500) {
          toast(err.response, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: 'text-danger toast-style-1' });
        }
      });
  };
  const ReassignOrder = () => {
    let data = {
      order_id: orderId,
    };
    axiosInstance
      .post("/user/products/reassign_order", data)
      .then((res) => {
        if (res.status == 200) {
          setDeliverystaffAccept('1');
          // setNoneExistStatus(0)
          if (orderId) {
            AssignOrder(orderId)
          }
        }
      })
      .catch((err) => {
        if (err.response.status == 500) {
          toast(err.response, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: 'text-danger toast-style-1' });

        }
      });
  };

  const deliveryStaffDetails = () => {
    let data = {
      order_id: orderId
    };
    axiosInstance
      .post("/user/products/get_delivery_staff_details", data)
      .then((res) => {

        if (res.status == 200) {
          setDeliverypartnerDetailse(res.data.delivery_staff_detail)
          // delivery_staff_status
          setDeliverystaffAccept("1")

        }
      })
      .catch((err) => {
        if (err.response.status == 500) {

          toast("something went wrong please try again")
        }
      });
  };
  const cancelConfirmedOrder = () => {
    let data = {
      order_id: orderId,
    };
    axiosInstance
      .post("/user/products/confirm_cancel_order", data)
      .then((res) => {
        if (res.status == 200) {
          handleOkClick()
          closecancelOrderModal()
        }
      })
      .catch((err) => {
        if (err.response.status == 500 || err.response.status == 400) {
          toast(err.response, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: 'text-danger toast-style-1' });
        }
      });
  };
  const ChooseSlotDelivery = () => {
    let data = {
      order_id: orderId,
      order_delivery_time: "",
      order_delivery_date: "ANY",
    };
    axiosInstance
      .post("/user/products/order_delivery_time_date_update", data)
      .then((res) => {
        if (res.status == 200) {
          handleOkClick()
        }
      })
      .catch((err) => {
        if (err.response.status == 500 || err.response.status == 400) {

        }
      });
  };
  const toggleContent = () => {
    setShowContent(!showContent);
  };
  const toggleaddon = () => {
    setShowaddons(!showaddons)
  }
  const handleOkClick = (type) => {
    if (type == "loyalty&cashafter") {
      navigate("/");
    } else {
      navigate("/ProductPass", { replace: true, state: { orderid: orderId, fromPage: "ReviewPay" } });
    }
    setShowModal(false);
    localStorage.removeItem("productCart");
    localStorage.removeItem("appliedCard");
  };

  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  const formatDateToCustomFormatWithTimeZone = (isoDate, timeZone) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZone: timeZone,
    };

    const date = new Date(isoDate);
    const formattedDate = date.toLocaleString("en-US", options);
    const day = date.getDate();
    const daySuffix = getDaySuffix(day);
    const modifiedFormattedDate = formattedDate.replace(
      day,
      `${day}${daySuffix}`
    );
    return modifiedFormattedDate;
  };

  const ChangeSlotType = () => {
    if (flowtype != "EDIT_ORDER") {
      setTimeChangeType(true)
      setDeliveryModelStatus(false)
    }

  }
  const getselectProductDetail = () => {
    try {
      if (cartId != "") {
        ;
        let reqData = {
          vendor_id: vendorId,
          cart_id: cartId,
          flow_type: flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : "ORDER"
        };
        console.log("req data", reqData);

        let usertoken = localStorage.getItem("token");
        let reqUrl = usertoken
          ? "user/products/product_checkout_details"
          : "user/products/product_checkout_details_without_login";


        axiosInstance.post(reqUrl, reqData)
          .then((res) => {
            let data = res.data;

            setProductDetail(data);
            setPaymenttype(data.payment_options);
            setProductlist(data.cart_products);
            setGststatus(data.gst_status);
            setOfferDetails(data.offer_detail);
            setBuyproduct(data.offer_detail.buy_products);
            setGetproduct(data.offer_detail.get_products);
            setOffertype(data.applied_card_detail.offer_type);

            setPageLoaded(true);
            const localProductDetail = localStorage.getItem("shipping_type")
              ? JSON.parse(localStorage.getItem("shipping_type"))
              : "";
            setShipingtype(
              localProductDetail?.shipping_type
                ? localProductDetail?.shipping_type
                : ""
            );
            if (res?.data?.delivery_location_status) {
              setDeliverylocation(res?.data?.delivery_location_address);
            }
            setShipingtype(data.shipping_type)
            if (fromPage == 'addAddress' && !res?.data?.delivery_location_status) {
              getuserlocationlist()
            }

          })
          .catch((err) => {
            if (err.response.status == 400 || err.response.status == 500) {
              toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
            }
          });
      }
    } catch (error) {
      toast(" please content pingle are Try again later", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  };

  const getuserlocationlist = () => {

    try {
      let reqData = {
        vendor_id: vendorId,
        cart_id: cartId,
      };
      axiosInstance
        .post("user/products/user_locations_list", reqData)
        .then((res) => {
          setUserLocation(res.data.user_locations);
          setUserlatlong(res.data.latlng);
          openAddLocationModal();

        })
        .catch((err) => {
          if (err.response.status == 400 || err.response.status == 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch { }
  };

  const select_Location = (latlng) => {
    try {
      let reqData = {
        cart_id: cartId,
        location: latlng,
      };
      axiosInstance
        .post("user/products/select_delivery_location", reqData)
        .then((res) => {
          closeLocationModal();
          setConfirmlocation(1);
          getselectProductDetail();
        })
        .catch((err) => {
          if (err.response.status == 400 || err.response.status == 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast(" please content pingle are Try again later", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  };

  const confirmticket = () => {
    try {
      let reqData = {
        cart_id: cartId,
        payment_method: selectpaytype,
        note_desc: consulation,
        flow_type: flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : "ORDER"
      };
      axiosInstance.post("/user/products/confirm_order", reqData)
        .then((res) => {
          openOrderSuccessModal();
          localStorage.removeItem('EditOrder_Detail');
        })
        .catch((err) => {
          if (err.response.status == 400 || err.response.status == 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast(" please content pingle are Try again later", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  };

  function openAddLocationModal() {
    document
      .getElementById("selectCustomerModalProductRP")
      .classList.add("show");
    setLocationBtnloder(true)
  }

  function closeBookForModal() {
    var modal = document.getElementById("selectCustomerModalProductRP");
    modal.classList.remove("show");
    setLocationBtnloder(true)
  }

  const confirmPayment = () => {
    try {
      let reqData = {
        cart_id: cartId,
        payment_method: flow == 'LoyaltyProduct' ? 4 : selectpaytype,
        note_desc: consulation,
        loyalty_applied: flow == 'LoyaltyProduct' ? 1 : 0,
      };

      axiosInstance.post("/user/products/payment_checkout_razorpay", reqData)
        .then((res) => {
          let data = res.data;
          let userDetatil = {
            name: "",
            phone: "",
            email: "",
          };
          setRazorpayOrderId(data?.payment_recipt?.id)
          if (flow == 'LoyaltyProduct') {
            redeemProduct_loyalty()
          } else {

            if (selectpaytype == 1 || selectpaytype == 2) {
              setPaymentOrderId(data?.payment_recipt?.id);
              setUserId(data?.user_id);
              handlePayment(userDetatil, data?.payment_recipt);
            } else {
              confirmticket();
              // navigate("/");
            }
          }
        }).catch((err) => {
          if (err.response.status == 400 || err.response.status == 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast(" please content pingle are Try again later", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  };

  const Add_Remove_productcount = (productId, Operation) => {
    try {

      const Addition = [];

      if (selectedAddons && selectedAddons.length > 0) {
        selectedAddons.forEach((addon) => {
          Addition.push(...addon.options);
        });
      }

      let reqData = {
        vendor_id: vendorId,
        product_id: productId,
        shipping_type: shipingtype,
        quantity: 1,
        operation: Operation,
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
        addons: Addition ? Addition : "",
        flow_type: flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : "ORDER"
      };

      axiosInstance
        .post("user/products/add_or_remove_product_cart_without_login", reqData)
        .then((res) => {
          if (res.status == 200) {
            if (res?.data?.cart_count == "0") {
              navigate(-1)
            } else {
              getselectProductDetail();
            }
          }
        })
        .catch((err) => {
          if (err.response.status == 400 || err.response.status == 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast(" please content pingle are Try again later", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  };
  const loadingTimeOutButton = () => {
    setIsLoading(true);
    setTimeout(() => { setIsLoading(false); }, 3000);
  }
  const handleConfirmPurchase = () => {
    if (flowtype == flowtypeValue && productDetail?.edit_order_datails?.pay_status == "1") {
      loadingTimeOutButton()
      confirmPayment();
    } else if (flowtype == flowtypeValue && productDetail?.edit_order_datails?.refund_status == "1") {
      loadingTimeOutButton()
      confirmEditOrder()
    }
    else {
      loadingTimeOutButton()
      confirmPayment();
    }
  };
  // refund_status
  const deleteProduct = () => {
    try {
      let reqData = {
        vendor_id: vendorId,
        product_id: deleteitem.product_id,
        shipping_type: shipingtype,
        quantity: deleteitem.added_count,
        operation: "remove",
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
        flow_type: flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : "ORDER"
      };
      axiosInstance
        .post("user/products/add_or_remove_product_cart_without_login", reqData)
        .then((res) => {
          if (res.data.cart_count == 0) {
            navigate(`/products/${vendorId}`)
          } else {
            getselectProductDetail();

          }
        }).catch((err) => {
          if (err.response.status == 400 || err.response.status == 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast(" please content pingle are Try again later", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  };
  const redeemProduct_loyalty = () => {
    try {

      let reqData = {
        "cart_id": cartId,
        "payment_method": '4',
        "offer_id": loyaltyOfferid,
        "loyalty_applied": '1'
      }
      const Loyaltyoffer_id = localStorage.getItem("token");
      axiosInstance.post('/user/products/redeem_vendor_loyalty', reqData)
        .then((res) => {
          openOrderSuccessModal();
        }).catch((err) => {
          if (err.response.status == 400 || err.response.status == 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast(" please content pingle are Try again later", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  }
  const handlePayment = useCallback(
    (userData, orderData) => {
      const createOrderAndInitializePayment = async () => {
        const options = {
          key: Razorpay_Key,
          amount: orderData?.amount,
          currency: orderData.currency,
          name: "PINGLE",
          description: "Payment",
          image: "https://pingle.in/user/assets/images/LogoUser.png",
          order_id: orderData.id,
          handler: (res) => {

            // if (onPayment) {
            //     onPayment(res); // Passing the order ID to the parent component
            // }
          },
          prefill: {
            name: userData.name,
            email: userData.email,
            contact: userData.phone,
          },
          theme: {
            color: "#4E26A3",
          },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
      };

      createOrderAndInitializePayment();
    },
    [Razorpay]
  );

  const openPaymentSuccessModal = () => {
    paymentSuccessModalRef.current.classList.add("show");
    paymentSuccessModalRef.current.style.display = "block";
  };
  const closePaymentSuccessModal = () => {
    paymentSuccessModalRef.current.classList.remove("show");
    paymentSuccessModalRef.current.style.display = "none";
  };
  const opencancelOrderModal = () => {
    cancelOrderModalRef.current.classList.add("show");
    cancelOrderModalRef.current.style.display = "block";
  };
  const closecancelOrderModal = () => {
    cancelOrderModalRef.current.classList.remove("show");
    cancelOrderModalRef.current.style.display = "none";
    openPaymentSuccessModal()
  };
  const openOrderSuccessModal = () => {
    orderSuccessModalRef.current.classList.add("show");
    orderSuccessModalRef.current.style.display = "block";
  };

  const closeLocationModal = () => {
    locationModalRef.current.classList.remove("show");
  };

  const handleLoginNavigate = () => {
    localStorage.setItem("vendorUrl", location.pathname);
    navigate("/login");
  };
  const toggleDatePickerOffCanvas = () => {
    setDatePickerOffCanvasOpen(!isDatePickerOffCanvasOpen);
    setDatePickerModal(false)
    ChangeSlotType()
  };
  const handelSelectTime = () => {
    let localServiceDetail = localStorage.getItem("productCart") ? JSON.parse(localStorage.getItem("productCart")) : "";
    if (localServiceDetail != "") {
      if (localServiceDetail.shipping_type == 2) {
        setDatePickerModal(true)
        setDatePickerOffCanvasOpen(true)
      }
    }
    setTimeChangeType(false)

  }
  useEffect(() => {

    let localTimeSlot = JSON.parse(localStorage.getItem("productDeliveryTime"))
    setSlotTime(localTimeSlot?.selectHour ? localTimeSlot?.selectHour : "")
    setDeliveryDate(localTimeSlot?.selectDate ? localTimeSlot?.selectDate : "")
    getselectProductDetail()

  }, [isDatePickerOffCanvasOpen, deliveryModelStatus])

  const Select_Delivery_Slot = (type) => {
    try {
      // let productCart = localStorage.getItem("productCart") ? JSON.parse(localStorage.getItem("productCart")) : "";

      let reqData = {
        "cart_id": cartId,
        "order_delivery_time": ' ',
        "order_delivery_date": type,
      }

      axiosInstance.post('/user/delivery_time_date_update', reqData)
        .then((res) => {
          localStorage.removeItem('productDeliveryTime')
          setDeliveryModelStatus(true);
          setTimeChangeType(false)
        }).catch((err) => {
          if (err.response.status == 400 || err.response.status == 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast(" please content pingle are Try again later", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  }
  const openAddonModal = () => {
    addonModalRef.current.classList.add("show");
    addonModalRef.current.style.display = "block";
  };
  const closeAddonModal = () => {
    addonModalRef.current.classList.remove("show");
    addonModalRef.current.style.display = "none";
  };


  const handelEdittoAddonmodel = () => {
    closeEditAddonModal()
    openAddonModal()
  }
  const transformDataAndCalculateTotalCost = (rawData, selectedOptions) => {
    let transformedData = [];
    let totalCost = 0;

    if (rawData && rawData.length > 0) {
      rawData.forEach((addon) => {
        let addonObj = {
          addonId: addon.id,
          options: []
        };

        addon.options.forEach((option) => {
          if (selectedOptions.includes(option.id)) {
            addonObj.options.push(option.id);
            totalCost += parseInt(option.price, 10);
          }
        });

        if (addonObj.options.length > 0) {
          transformedData.push(addonObj);
        }
      });
    }

    return { addons: transformedData, cost: totalCost };
  };
  const selectAddon = (addon, option, price) => {
    let addonId = addon.id;
    let selectedAddonsList = [...selectedAddons];
    let found = false;

    selectedAddonsList.forEach(item => {
      if (item.addonId === addonId) {
        found = true;
        if (!item.options.includes(option)) {
          if ((addon.selection_type == 1 && item.options.length > 0) || (addon.selection_type == 2 && item.options.length >= addon.max_select)) {

            toast("You have already selected max addons for this category", {
              position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: 'text-danger toast-style-1'
            });
          } else {
            item.options.push(option);
            setProductCost(Number(productCost) + Number(price));
            setAddonsCost(Number(addonsCost) + Number(price));
          }
        } else {
          item.options = item.options.filter((opt) => opt !== option);
          setProductCost(Number(productCost) - Number(price));
          setAddonsCost(Number(addonsCost) - Number(price));

        }
      }
    });

    if (!found) {
      selectedAddonsList.push({ "addonId": addonId, "options": [option] });
      setProductCost(Number(productCost) + Number(price));
      setAddonsCost(Number(addonsCost) - Number(price));
    }
    setSelectedAddons(selectedAddonsList);
  }

  const checkIsSelectedAddon = (addonId, option) => {
    let selectedAddonsList = [...selectedAddons];
    let found = false;
    selectedAddonsList.forEach(item => {
      if (item.addonId === addonId) {
        if (item.options.includes(option)) {
          found = true;
        }
      }
    });

    return found;
  }

  const checkButtonStatus = () => {

    const selectedObject = productDetail?.cart_products.find(item => item.product_id == productid);

    for (const addon of selectedObject?.addons) {
      const selectedAddon = selectedAddons.find((sel) => sel.addonId == addon.id);
      const selectedOptionsCount = selectedAddon ? selectedAddon.options.length : 0;

      if (addon?.required == 1 && addon.selection_type == 2 && selectedOptionsCount < addon.min_select) {
        toast(`You need to select at least ${addon.min_select} option(s) from the category ${addon.addon_name}`, {
          position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: 'text-danger toast-style-1 toast-custom-style' // Add your custom class here
        });
        return;
      } else if (addon?.required == 1 && addon.selection_type == 1 && selectedOptionsCount <= 0) {

        toast(`You need to select at least 1 option(s) from the category ${addon.addon_name}`,
          { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: 'text-danger toast-style-1 toast-custom-style' });

        return;
      }
    }

    closeAddonModal()
    closeEditAddonModal()
    Add_Remove_productcount(productid, "add", "1");
  }

  const openEditAddonModal = (addons, addedAddons, productid, added_count, items) => {
    let transformedData = transformDataAndCalculateTotalCost(addons, addedAddons);
    setSelectedAddons(transformedData.addons);
    setProductid(productid);
    setProductCount(added_count)
    setSelectItem(items)

    addonEditeModalRef.current.classList.add("show");
    addonEditeModalRef.current.style.display = "block";
    setAddonModel(true)
  };
  const closeEditAddonModal = () => {
    addonEditeModalRef.current.classList.remove("show");
    addonEditeModalRef.current.style.display = "none";
    // setModelOpen(true)
  };
  const update_Product = async (productId, Operation, count) => {
    try {
      // if (addonstate != "0") {
      let addons = [];
      selectedAddons?.map((addon) => {
        addons = [...addons, ...addon.options];
        // setReqAddon(addons)
      });
      // }
      let reqData = {
        vendor_id: merchantId.vendor_id,
        product_id: productId,
        shipping_type: shipingtype,
        quantity: count,
        operation: Operation,
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
        addons: addons,
        flow_type: flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : "ORDER"
      };

      axiosInstance.post("user/products/add_or_remove_product_cart_without_login", reqData)
        .then((res) => {
          if (res.status == 200) {
            getselectProductDetail();
            setSelectedAddons([]);
            closeEditAddonModal()
          }
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast(`You can't add more than ${productCount} of this product`, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  };
  const confirmEditOrder = () => {
    try {
      let reqData = {
        cart_id: cartId
      }

      axiosInstance.post("/user/products/confirm_edit_order", reqData)
        .then((res) => {
          navigate("/")
          localStorage.removeItem('EditOrder_Detail');

        }).catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast(`You can't add more than ${productCount} of this product`, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  };
  const confirmUpdate = () => {
    let ProductItem = productDetail?.cart_products
    var ProductHave = ProductItem?.some((obj) => obj.product_id == productid);
    ProductItem?.map((Cartdata, id) => {

      if (Cartdata.product_id == productid) {
        if (Cartdata.quantity > productCount) {
          update_Product(productid, 'remove', Cartdata.added_count - productCount);
          setAddonModel(false)
        } else if (productCount > Cartdata.added_count) {
          update_Product(productid, 'add', productCount - Cartdata.added_count);
          setAddonModel(false)
        } else {
          setAddonModel(false)
          closeEditAddonModal()

        }
      }
    })

  }
  const [showAddonsState, setShowAddonsState] = useState({});

  const toggleAddon = (index) => {
    setShowAddonsState((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };




  return (
    <>

      <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white  " >
        <div className="d-flex justify-content-between align-items-center ms-2 me-2">
          <img src={p_nav_previous} width={"24px"} height={"16px"} alt="Previous Button" role="button" onClick={() => navigate(-1)} />
          <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
            Review & Pay
          </div>
        </div>
      </div>

      {
        pageLoaded ?
          <div className="scroller-style-y pisition-relative">
            <div className="p-2 secondary-bg-1">
              {!token ? (
                <div className="border border-black border-opacity-10 w-100 rounded-4 p-3 bg-white">
                  <div className="primary-text ff-balooPaaji2-regular">
                    Before making a purchase, please log in.
                  </div>
                  <div>
                    <button className="w-100 btn border-0 primary-bg text-white ff-balooPaaji2-semiBold py-2 rounded mt-2" onClick={handleLoginNavigate}  >
                      Login
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* Edit addon model  */}
            <div className="offcanvas addOnModel_scroll" tabIndex="-1" style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15, width: 390, zIndex: 7777, }} ref={addonEditeModalRef} >
              <div className=" d-flex justify-content-end mt-2" onClick={() => closeEditAddonModal()}>
                <span className="closeIcon" ><IoMdClose /> </span>
              </div>
              <div className="p-3">
                <div className="d-flex justify-content-between">
                  <p className="m-0 ff-poppins-semiBold fs-14">Your Chosen</p>
                  <p className="m-0 ff-poppins-regular fs-14 primary-text" onClick={() => handelEdittoAddonmodel()}>Edit</p>
                </div>

                {productDetail?.cart_products?.length > 0 &&
                  <div>
                    {productDetail?.cart_products?.map((item) => {

                      return productid === item.product_id ?
                        item?.added_addons?.length > 0 ?
                          item?.addons.map((addon) =>
                            addon?.options?.map((option, optionIndex) => {

                              return item?.added_addons?.includes(option?.id) && (
                                <div className="d-flex justify-content-between" key={optionIndex}>
                                  <div className="d-flex mt-1">
                                    <img src={option.type === "1" ? vegIcon : option.type === "2" ? nonvegIcon : eggIcon} height="13px" width="13px" className="me-1 mt-1" />
                                    <div className="ff-poppins-regular fs-13 ms-1">
                                      {option?.name}
                                    </div>
                                  </div>
                                  <div className="d-flex me-4">
                                    <div className="ff-poppins-semiBold fs-12 mt-1 me-1">
                                      + {option?.price}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) :
                          <div className="ff-poppins-semiBold text-center fs-12 mt-1 me-1">
                            No addons added
                          </div>
                        : null;
                    }

                    )}
                  </div>
                }
                <div className="mt-3" style={{ backgroundColor: '#fff', position: "sticky", bottom: 0, width: '100%' }}>
                  <div className=" ">
                    {productDetail?.cart_products.length > 0 &&
                      productDetail?.cart_products?.map((item) => (
                        <div className="ff-poppins-regular ">
                          {productid == item.product_id && item.added_count > 0 && (
                            <div>
                              <div className="d-flex justify-content-between">
                                <div className="  d-flex fs-14 col-4  ">
                                  <div className="col-2">
                                    <FiMinusCircle
                                      className="  primary-text fontSizeCountIcon"
                                      onClick={() => {
                                        setProductCount((prevCount) => prevCount - 1);
                                        setProductCost(item?.price);
                                      }} />
                                  </div>
                                  <div className=" col-2 ms-1 d-flex justify-content-center">
                                    {productCount}
                                  </div>
                                  <div className="col-2">
                                    <LuPlusCircle
                                      className={` primary-text fontSizeCountIcon ${productCount == item.max_allowed_quantity ? "opacity_Cls rounded-circle" : ""}`}
                                      onClick={() => {
                                        if (productCount != item?.max_allowed_quantity) {
                                          setProductCount((prevCount) => prevCount + 1);
                                          setProductid(item.product_id)
                                        } else {
                                          toast("You have reached maximum order product", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
                                        }
                                      }} />
                                  </div>
                                </div>
                                <div className="ff-poppins-semiBold fs-14 primary-text">
                                  Cost : {(productCount) * (Number(item.price) + Number(item.addons_cost))}
                                </div>
                              </div>
                              <div className="col-12">
                                <button type="button" className="btn-type-1   ff-poppins-semiBold mt-2 fs-14 w-100 primary-bg border-0 text-white justify-content-center d-flex align-items-center"
                                  onClick={() => confirmUpdate()} >
                                  Confirm
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* Add addon model  */}
            <div className="offcanvas addOnModel_scroll" tabIndex="-1" style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15, width: 390, zIndex: 7777, maxHeight: "60%" }} ref={addonModalRef}  >
              <div className=" d-flex justify-content-end mt-2" onClick={() => closeAddonModal()}>
                <span className=" closeIcon " ><IoMdClose /> </span>
              </div>
              {productDetail?.cart_products?.length > 0 &&
                <div>
                  {productDetail?.cart_products?.map((item, productItemIndex) => {
                    return productid == item?.product_id ?
                      item?.addons?.map((addon, addonIndex) => (
                        <div className="offcanvas-body small" >
                          <div>
                            <div className="mb-2">
                              <h6 className="m-0 ff-poppins-semiBold fs-14">choose {addon?.addon_name}</h6>
                              <div className="secondary-text fs-13">select any (optional)</div>
                            </div>
                            {addon?.options?.map((option, optionIndex) => (
                              <div className="d-flex justify-content-between" >
                                <div className="d-flex mt-1   ">
                                  <img src={option.type === "1" ? vegIcon : option.type === "2" ? nonvegIcon : eggIcon} height="13px" width="13px" className="me-1 mt-1" />
                                  <div className="ff-poppins-regular fs-13 ms-1">{option?.name}</div>
                                </div>
                                <div className="d-flex">
                                  <div className="ff-poppins-semiBold fs-12 mt-1 me-1">+ {option?.price}</div>
                                  {
                                    checkIsSelectedAddon(addon.id, option.id) ?
                                      <div onClick={() => { selectAddon(addon, option?.id, option?.price); setProductid(item?.product_id) }} className="primary-text fs-15 ff-poppins-semiBold"> <MdOutlineCheckBox /> </div> :
                                      <div onClick={() => { selectAddon(addon, option?.id, option?.price); setProductid(item?.product_id) }} className="fs-15 ff-poppins-semiBold " >    <MdOutlineCheckBoxOutlineBlank /></div>
                                  }
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )) : ""
                  })}
                </div>
              }
              <div style={{ backgroundColor: '#fff', position: "sticky", bottom: 0, width: '100%' }}>
                <div className=" p-1  ">
                  <p className="m-0 d-flex justify-content-end primary-text ff-poppins-regular fs-14 mb-1">
                    Cost: {(Number(productCost)) + ((productCount) * (Number(selectItem.price) + Number(selectItem.addons_cost)))}
                  </p>
                  <button type="button" className="btn-type-1 ff-poppins-semiBold fs-14 w-100 primary-bg border-0 text-white justify-content-center d-flex align-items-center"
                    onClick={() => checkButtonStatus()}  >
                    Confirm
                  </button>
                </div>

              </div>
            </div>



            <div className="p-2 secondary-bg-1">
              <div className="p-3 bg-white rounded-4">
                {shipingtype == '2' ? (
                  <div className="mb-4 mt-4">
                    <div className="ff-balooPaaji2-bold mb-2">
                      Delivery Address
                    </div>
                    {deliverylocation.length === 0 ? (
                      <button disabled={token ? false : true} className={"border border-black border-opacity-10 w-100 ff-balooPaaji2-semiBold rounded p-2 bg-white "}
                        onClick={() => { getuserlocationlist(); setLocationBtnloder(false) }}  >
                        {locationBtnloder ? "  Add Address"
                          : <div className="d-flex justify-content-center">
                            <ReactLoading type={"spinningBubbles"} color={"#4E26A3"} style={{ height: 24, width: 24 }} />
                          </div>} </button>
                    ) : (
                      <div onClick={() => flowtype != "EDIT_ORDER" ? getuserlocationlist() : ""} >
                        <div className={`border rounded d-flex p-2 ${flowtype == "EDIT_ORDER" ? "opacity_Cls" : ""} `}>
                          <div className="col-2 ">
                            <div className="border w-100 border-black border-opacity-10 rounded d-flex justify-content-center mt-2 fs-24 primary-text py-2">
                              {deliverylocation?.location_type_id == 1 ? (
                                <IoMdHome className="primary-text" />
                              ) : deliverylocation?.location_type_id == 2 ? (
                                <MdWork className="primary-text" />
                              ) : (
                                <IoLocationSharp />
                              )}
                            </div>
                          </div>
                          <div className="col-9">
                            <p className="m-0 ps-3 ff-poppins-bold">
                              {deliverylocation?.location_type}{" "}
                            </p>
                            <p className="m-0 ps-3 ff-poppins-regular singleline-text fs-12">
                              {deliverylocation?.address}
                            </p>
                            <p className="m-0 ps-3 ff-poppins-regular fs-12">
                              {deliverylocation?.distance.text} - {deliverylocation?.duration.text}
                            </p>
                          </div>
                          <div className="col-1 ms-2 mt-3">
                            <IoIosArrowForward />
                          </div>
                        </div>
                      </div>
                    )}

                  </div>
                ) :
                  <></>
                }

                {
                  shipingtype == '2' ?
                    productDetail?.delivery_slot_status == '0' || timeChangeType == true ?
                      <div className="mt-2 " >
                        <div className="ff-balooPaaji2-bold mb-2 ">
                          Delivery TimeSlot
                        </div>
                        <div className="d-flex justify-content-around mt-2">
                          <button className="timeSlotBtn rounded-3 ff-balooPaaji2-bold fs-14 primary-text p-1 col-5" onClick={() => Select_Delivery_Slot("ANY")}>Any Time</button>
                          <button className="timeSlotBtn rounded-3 ff-balooPaaji2-bold fs-14 primary-text p-1 col-5" onClick={() => { if (deliveryModelStatus == false) { handelSelectTime() } else { } }}>Select Time</button>
                        </div>
                        <p className="ff-balooPaaji2-bold fs-14 primary-text m-0 d-flex justify-content-center">OR</p>
                        <div className="d-flex justify-content-center align-items-center">
                          <button className="timeSlotBtn rounded-3 ff-balooPaaji2-bold fs-14 primary-text p-1 col-5" onClick={() => {
                            Select_Delivery_Slot("INSTANT");
                            setInstantDelivery(true)
                          }}>Instant</button>
                        </div>

                      </div> :
                      <div className="mt-2">
                        <div className={`border border-2 rounded-3 ${flowtype == "EDIT_ORDER" ? "opacity_Cls" : ""}}`}>
                          <div className="p-2">
                            {
                              productDetail?.delivery_slot_type != '3' ?
                                <div>
                                  <div className="d-flex justify-content-between primary-text">
                                    <span className="ff-balooPaaji2-bold fs-16 mt-1">Delivery Time</span>
                                    <p className="m-0 d-flex justify-content-end primary-text ff-poppins-regular mt-2 fs-12" onClick={() => ChangeSlotType()}  >Change Type <span><IoIosArrowForward /> </span> </p>
                                  </div>
                                  <p className="fs-12 ff-poppins-regular secondary-text m-0">Choose time when order will be delivered</p>
                                  <hr />
                                </div>
                                : ""
                            }
                            <div className="d-flex ">
                              {
                                productDetail?.delivery_slot_type == '2' ?
                                  <div className="d-flex justify-content-center align-items-center col-11">
                                    <span className="me-2  primary-text fs-14 ff-balooPaaji2-bold">
                                      <BsClock />
                                    </span>
                                    <p className="ff-poppins-bold fs-14 m-0  primary-text">Any Time Delivery</p>
                                  </div>
                                  :
                                  productDetail?.delivery_slot_type == '1' ?
                                    <div className="d-flex col-11 m-0">
                                      <div className=" d-flex col-7 mt-1 ff-poppins-regular">
                                        <span className="me-1 primary-text ff-balooPaaji2-bold">
                                          <CiCalendar />
                                        </span>
                                        <div className="fs-12 mt-1">
                                          {new Date(productDetail?.order_delivery_date_time?.delivery_date.substring(0, 10)).toDateString().substring(4, 20)}
                                        </div>
                                      </div>
                                      <div className=" d-flex col-5 mt-2 ff-poppins-regular fs-12">
                                        <span className="me-1 primary-text ff-balooPaaji2-bold">
                                          <BsClock />
                                        </span>
                                        {productDetail?.order_delivery_date_time?.delivery_time}
                                      </div>
                                    </div>
                                    : productDetail?.delivery_slot_type == '3' ?
                                      <div className="col-12">
                                        <p className="m-0 d-flex justify-content-end primary-text ff-poppins-regular fs-12" onClick={() => ChangeSlotType()}>Change Type <span><IoIosArrowForward /> </span> </p>
                                        <div className="  col-12">
                                          <div className="d-flex justify-content-center align-items-center col-11  ">
                                            <span className="me-2  primary-text fs-14 ff-balooPaaji2-bold">
                                              <FaShippingFast />
                                            </span>
                                            <p className="ff-poppins-bold fs-14 m-0  primary-text ">Instant Delivery</p>
                                          </div>
                                        </div>
                                      </div>
                                      : ""
                              }
                              {productDetail?.delivery_slot_type != '3' ?
                                <div className="">
                                  <IoIosArrowForward onClick={() => handelSelectTime()} />
                                </div> : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    :
                    <></>

                }

                <p className="ff-poppins-bold m-0 fs-14 mt-3">{initiateTime}</p>

                {productDetail &&
                  productDetail?.card_applied_status == 1 &&
                  productDetail?.applied_card_detail?.card_type == "offer" &&
                  productDetail?.applied_card_detail?.offer_type == 3 ? (
                  <div className="offcanvas-parentType3 position-relative">
                    <h6 className="ff-poppins-bold">Buy products</h6>
                    {productDetail &&
                      productDetail?.offer_detail?.buy_products &&
                      productDetail?.offer_detail?.buy_products?.length > 0 ? (
                      productDetail?.offer_detail?.buy_products.map(
                        (buy, index) => {
                          return (
                            <div className="productCardHead">
                              <div>
                                <div className="productRPDiv">
                                  <div className="col-5 productImageRPDiv">
                                    <img src={buy.cover_image.image == "" ? defaultproductimg : buy.cover_image.image_url} className="productImageBasket" />
                                  </div>
                                  <div className="col-5">
                                    <p className="productNameBasket ff-balooPaaji2">
                                      {buy.product_name}
                                    </p>
                                    <p className="productNameBasket ff-balooPaaji2">
                                      ₹{buy.price}{" "}
                                    </p>
                                    <p className="productWeightBasket primary-text ff-balooPaaji2">
                                      {buy.unit_type} {buy.unit_type_name}
                                    </p>
                                    <div className="moreDetailsDiv">
                                      <p
                                        className="productWeightBasket primary-text ff-balooPaaji2"
                                        onClick={() => navigate(`/singleProductDetails/${vendorId}/${buy.product_id}`, { state: { productId: buy.product_id, merchantId, }, })}  >
                                        More details
                                      </p>
                                      <IoIosArrowDown className="fw-bold productWeightBasket primary-text" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <></>
                    )}
                    <h6 className="ff-poppins-bold">
                      Get products<span className="primary-text">(Free)</span>{" "}
                    </h6>
                    {productDetail && productDetail?.offer_detail?.get_products && productDetail?.offer_detail?.get_products?.length > 0 ? (
                      productDetail?.offer_detail?.get_products.map((get, index) => {
                        return (
                          <div className="productCardHead">
                            <div>
                              <div className="productRPDiv">
                                <div className="col-5 productImageRPDiv">
                                  <img src={get.cover_image.image == "" ? defaultimg : get.cover_image.image_url} className="productImage" />
                                </div>
                                <div className="col-5">
                                  <p className="productNameBasket ff-balooPaaji2">
                                    {get.product_name}
                                  </p>
                                  <p className="productNameBasket ff-balooPaaji2">
                                    ₹{get.price}{" "}
                                  </p>
                                  <p className="productWeightBasket primary-text ff-balooPaaji2">
                                    {get.unit_type} {get.unit_type_name}
                                  </p>
                                  <div className="moreDetailsDiv">
                                    <p className="productWeightBasket primary-text ff-balooPaaji2"
                                      onClick={() => navigate(`/singleProductDetails/${vendorId}/${get.product_id}`, { state: { productId: get.product_id, merchantId, }, })} >
                                      More details
                                    </p>
                                    <IoIosArrowDown className="fw-bold productWeightBasket primary-text" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <div>
                    <h6 className="ff-poppins-bold m-0 py-2">Products</h6>
                    {productlist.map((item, index) => {
                      return (
                        <div className="offcanvas-parentType3 position-relative">
                          <div className="productCardHead">
                            <div>
                              <div className="productRPDiv">
                                <div className="col-5 productImageRPDiv">
                                  <img src={item.cover_image.image == "" ? defaultproductimg : item.cover_image.image_url} className="productImageBasket" />
                                </div>

                                <div className="col-5">
                                  <p className="ff-poppins-semiBold  fs-12 m-0">
                                    <img src={item.food_type == "1" ? vegIcon : item.food_type == "2" ? nonvegIcon : ""} height="13px" width="13px" />  {item.product_name}{" "}
                                  </p>
                                  <p className="ff-poppins-semiBold  fs-12 me-1 m-0">
                                    <FaRupeeSign />{item.price}{" "}
                                  </p>
                                  <p className="ff-poppins-semiBold primary-text fs-9 m-0">
                                    {item.unit_quantity} {item.unit_type_name}
                                  </p>
                                  <div className="moreDetailsDiv primary-text">
                                    <p className="moreInfobtn ff-poppins-semiBold fs-10 px-0 m-0"
                                      onClick={() => navigate(`/singleProductDetails/${vendorId}/${item.product_id}`, { state: { productId: item.product_id, merchantId, }, })}  >
                                      More details
                                    </p>
                                    <IoIosArrowDown className="fw-bold productWeightBasket " />
                                  </div>
                                  {productDetail &&
                                    productDetail?.card_applied_status == 1 &&
                                    productDetail?.applied_card_detail?.card_type == "offer" &&
                                    productDetail?.applied_card_detail
                                      ?.offer_type == 2 ? (
                                    <></>
                                  ) : (
                                    flow != 'LoyaltyProduct' && productDetail?.card_applied_status == "0" ?
                                      <div className="row align-items-center w-50 gx-0">
                                        <div className="row d-flex justify-content-between w-100 gx-0">
                                          <div className="col-3">
                                            <FiMinusCircle className="w-100 fs-18 primary-text" onClick={() => Add_Remove_productcount(item?.product_id, "remove")} />
                                          </div>
                                          <div className="col-4 d-flex justify-content-center  primary-text">
                                            {item?.added_count}
                                          </div>
                                          <div className="col-3">
                                            <LuPlusCircle className={`w-100 primary-text fs-18  ${item?.added_count == item?.max_allowed_quantity ? "opacity_Cls rounded-circle" : ""}`}
                                              onClick={() =>
                                                item?.addons == "" ? item?.added_count == item?.max_allowed_quantity ? "" : Add_Remove_productcount(item?.product_id, "add") : openEditAddonModal(item?.addons, item?.added_addons, item?.product_id, item?.added_count, item)} />
                                          </div>
                                        </div>
                                      </div>
                                      : <></>
                                  )}
                                </div>

                                {productDetail && productDetail?.card_applied_status == 1 &&
                                  productDetail?.applied_card_detail?.card_type == "offer" && productDetail?.applied_card_detail?.offer_type == 2 ? (
                                  <></>
                                ) : (
                                  flow != 'LoyaltyProduct' ?
                                    <div className="col-2 deleteIconProductBasket">
                                      <MdOutlineDeleteOutline
                                        className="ms-2"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModaldelete"
                                        onClick={() => setDeleteitem(item)} />
                                    </div>
                                    : <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {/* Payment method  */}
                {
                  flow != 'LoyaltyProduct' ?
                    <div>
                      <p className="ff-poppins-bold fs-18">Payment method</p>
                      {productDetail &&
                        productDetail.payment_options &&
                        (productDetail.shipping_type == 1 ||
                          productDetail.shipping_type == 2) ? (
                        <>
                          <div className="d-flex justify-content-between mb-0" onClick={() => flowtype != "EDIT_ORDER" ? setSelectPaytype(1) : ""} >
                            <div>
                              <p className="mb-0 ff-poppins-semiBold"> Pay Full </p>
                              <p className="fs-11 ff-poppins-regular secondary-text">pay total amount now</p>
                            </div>
                            {selectpaytype == 1 ? (
                              <MdOutlineCheckCircle className="selecticon" />
                            ) : (
                              <MdOutlineRadioButtonUnchecked />
                            )}
                          </div>

                          {productDetail.payment_options.advance_payment == '1' ? (
                            <div className="d-flex justify-content-between mb-0" onClick={() => flowtype != "EDIT_ORDER" ? setSelectPaytype(2) : ""}  >
                              <div>
                                <p className="mb-0 ff-poppins-semiBold">
                                  Pay Advance
                                </p>
                                <p className="fs-11 ff-poppins-regular secondary-text">
                                  Pay a merchant-determined amount upfront now.
                                </p>
                              </div>
                              {selectpaytype == 2 ? (
                                <MdOutlineCheckCircle className="selecticon" />
                              ) : (
                                <MdOutlineRadioButtonUnchecked />
                              )}
                            </div>
                          ) : (
                            <></>
                          )}

                          {productDetail?.payment_options?.cash_after_delivery == '1' ? (
                            <div className="d-flex justify-content-between mb-0" onClick={() => flowtype != "EDIT_ORDER" ? setSelectPaytype(3) : ""}  >
                              <div>
                                <p className="mb-0 ff-poppins-semiBold">
                                  Cash after product
                                </p>
                                <p className="fs-11 ff-poppins-regular secondary-text">
                                  pay total amount now
                                </p>
                              </div>
                              <div className="d-flex gap-2">
                                {selectpaytype == 3 ? (
                                  <MdOutlineCheckCircle className="selecticon" />
                                ) : (
                                  <MdOutlineRadioButtonUnchecked />
                                )}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {productDetail.payment_options.cash_after_service == 1 ? (
                            <div className="d-flex justify-content-between mb-0" onClick={() => { setSelectPaytype(4); }}  >
                              <div>
                                <p className="mb-0 ff-poppins-semiBold">
                                  Cash after service
                                </p>
                                <p className="fs-11 ff-poppins-regular secondary-text">
                                  Pay in cash at the store after service completion.
                                </p>
                              </div>
                              <div className="d-flex gap-2">
                                {selectpaytype == 4 ? (
                                  <MdOutlineCheckCircle className="selecticon" />
                                ) : (
                                  <MdOutlineRadioButtonUnchecked />
                                )}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div> : <></>
                }
                <div>
                  <div className="d-flex justify-content-between" onClick={toggleContent}   >
                    <div>
                      <h6 className="bookInfoTextRP ff-poppins-bold">
                        <b>Billing Information</b>
                      </h6>
                    </div>
                    <div>   {showContent ? (<SlArrowUp className="me-2" />) : (<SlArrowDown className="me-2" />)}
                    </div>
                  </div>
                </div>

                <div>
                  {showContent && (
                    <div>
                      <div className="ff-poppins-regular fs-13">
                        <p>Booking Initiated at {initiateTime}</p>
                      </div>
                    </div>
                  )}

                  <hr className="my-2" />
                  {productDetail &&
                    productDetail?.card_applied_status == 1 &&
                    productDetail?.applied_card_detail?.card_type == "offer" &&
                    productDetail?.applied_card_detail?.offer_type == 3 ? (
                    <div className="servicePriceHeadDiv">
                      <h6 className="serviceTextRP">
                        <b>Buy Products</b>
                      </h6>
                      {buyproduct.map((item, index) => {
                        return (
                          <div className="d-flex justify-content-between">
                            <p className="m-0"> {item.product_name}</p>
                            <p className="m-0">
                              {item.added_count} x {item.price}.00
                            </p>
                          </div>
                        );
                      })}
                      <div className="totalBar fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                        <p className="m-0 text-success">Total</p>
                        <p className="m-0 text-success">
                          Rs {offerDetails.buy_products_total}
                        </p>
                      </div>
                      <h6 className="serviceTextRP">
                        <b>Get Products</b>
                      </h6>
                      {getproduct.map((item, index) => {
                        return (
                          <div className="d-flex justify-content-between ff-poppins-regular fs-13">
                            <p className="m-0"> {item.product_name}</p>
                            <p className="m-0">
                              {item.added_count} x {item.price}.00
                            </p>
                          </div>
                        );
                      })}
                      <div className="totalBar text-success fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                        <p className="m-0">Total</p>
                        <p className="m-0">
                          Rs {offerDetails.get_products_total}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="servicePriceHeadDiv" style={{ paddingBottom: -10 }}   >
                      <h6 className="serviceTextRP">
                        <b>Products</b>
                      </h6>
                      <div>
                        {productlist.map((item, index) => (
                          <div key={index}>
                            <div className="d-flex justify-content-between ff-poppins-regular fs-13">
                              <p className="m-0">{item.product_name}</p>
                              <div className="d-flex">

                                {

                                  item?.added_addons_details != "" ?
                                    <div onClick={() => toggleAddon(index)} style={{ cursor: 'pointer' }} >
                                      {

                                        showAddonsState[index] ? (
                                          <SlArrowUp className="me-2" />
                                        ) : (
                                          <SlArrowDown className="me-2" />
                                        )
                                      }
                                    </div>
                                    : ""
                                }

                                <p className="m-0 ms-2">
                                  {item.added_count} x {Number(item.price) + Number(item.addons_cost)}
                                </p>
                              </div>

                            </div>
                            <div className="m-0 fs-12 col-10">
                              {showAddonsState[index] ? (
                                item?.added_addons_details.map((addon, addonIndex) => (
                                  <div key={addonIndex} className="mt-1 d-flex justify-content-between ff-poppins-regular fs-12"  >
                                    <div>
                                      {addon.addon_name} - {addon.name}
                                    </div>
                                    <div>
                                      {item.added_count} x {addon.price}
                                    </div>
                                  </div>
                                ))
                              ) : (
                                item?.added_addons_details.map((addon) => addon.name.trim()).join(', ')
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                      {productDetail && productDetail?.discount_status != "" ? (
                        <>
                          <div style={{ borderBottomColor: "gray", borderBottomWidth: 2, borderBottomStyle: "dashed", marginTop: 10, }} ></div>
                          <div className=" text-success fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                            <p className="m-0">
                              {productDetail?.discount_detail?.lable}
                            </p>
                            <p className="m-0">
                              Rs {productDetail?.discount_detail?.discount_amount}
                            </p>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}

                  {/* <div className="dashBorderRP"></div> */}
                  <div className="servicePriceHeadDiv">
                    {
                      flow == 'LoyaltyProduct' ?
                        <div className="ff-poppins-regular fs-13" >
                          <div className="d-flex justify-content-between">
                            <p className="m-0">Subtotal</p>
                            <p className="m-0">{productDetail.sub_total} </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="m-0">Pingle Points ({offerLoyaltyPoints})</p>
                            <p className="m-0"> - {productDetail.sub_total} </p>
                          </div>
                        </div>
                        :
                        <div className="ff-poppins-regular fs-13 ">
                          <div className="d-flex justify-content-between">
                            <p className="m-0">Subtotal</p>
                            <p className="m-0">{productDetail.sub_total} </p>
                          </div>
                          {
                            productDetail?.delivery_charges_status == true ?
                              <div className="d-flex justify-content-between">
                                <p className="m-0">Delivery Charge</p>
                                <p className="m-0">{productDetail.delivery_charges_amount
                                } </p>
                              </div> : ""

                          }

                          <div className="d-flex justify-content-between">
                            <p className="m-0">Packing cost</p>
                            <p className="m-0">{productDetail.total_packing_cost} </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="m-0">Platform Charges</p>
                            <p className="m-0">{productDetail.user_platform_charges}</p>
                          </div>
                        </div>
                    }
                    {flow != 'LoyaltyProduct' && gststatus == true ? (
                      <div className="ff-poppins-regular fs-13">
                        <div className="d-flex justify-content-between">
                          <p className="m-0">SGST</p>
                          <p className="m-0">{productDetail.total_sgst_amount}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="m-0">CGST</p>
                          <p className="m-0">{productDetail.total_cgst_amount}</p>
                        </div>
                      </div>
                    ) :
                      <></>
                    }
                    {flow != 'LoyaltyProduct' && productDetail.round_off != 0 ? (
                      <div className="d-flex justify-content-between ff-poppins-regular fs-13">
                        <p className="m-0">Round Off</p>
                        <p className="m-0">{productDetail.round_off}</p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="dashBorderRP"></div>

                  <div className="d-flex justify-content-end">
                    <div className="fs-15 mt-2 ff-poppins-bold">
                      {flow === 'LoyaltyProduct' ? (
                        <b>Total - Rs <span>0</span></b>
                      ) : (
                        <div>
                          <div className="d-flex justify-content-between">
                            <span>Total</span>
                            <span>- Rs {productDetail?.total_amount}</span>
                          </div>
                          {flowtype == "EDIT_ORDER" && (
                            <div>
                              <div className="d-flex justify-content-between primary-text mt-1 mb-1">
                                <span>Paid</span>
                                <span>- Rs {productDetail?.edit_order_datails?.paid_amount}</span>
                              </div>
                              <div className="d-flex justify-content-between">
                                <span>To {productDetail?.edit_order_datails?.refund_status === 1 ? "Refund" : "Pay"}</span>
                                <span>- Rs {Math.abs(productDetail?.total_amount - JSON.parse(productDetail?.edit_order_datails?.paid_amount))}.00</span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {selectpaytype == 2 ? (
                    <div className="fs-16 prima primary-text fw-bold">
                      <p className="d-flex justify-content-end m-0 ">
                        Advance amount - Rs {productDetail?.payment_options?.advance_amount}
                      </p>
                      <p className="d-flex justify-content-end m-0">
                        Balance amount - Rs {productDetail?.payment_options?.balence_amount}
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>


            <div className="offcanvas offcanvas-bottomm Chooseaddres main"
              tabIndex="-1" id="selectCustomerModalProductRP" aria-labelledby="offcanvasBottomLabel" data-bs-backdrop="false" ref={locationModalRef} style={{ maxHeight: '70vh', position: 'absolute' }}>
              <div className="offcanvas-body small bg-light position-relative">
                <div className="d-flex justify-content-between">
                  <div className="ff-poppins-semiBold">
                    <h5>Choose address</h5>
                  </div>
                  <div onClick={() => closeBookForModal()}>
                    <CgCloseO />
                  </div>
                </div>
                <div style={{}}>
                  {userLocation.map((location, index) => {
                    return (
                      <div onClick={() => location.select_status ? select_Location(location.latlng) : ""}  >
                        <div className="d-flex align-items-center mt-4" style={{ opacity: location.select_status == 0 ? 0.4 : 1 }}  >
                          <div className="col-2">
                            <div className="border w-100 border-black border-opacity-10 rounded d-flex justify-content-center fs-24 primary-text py-2">
                              {location.location_type_id == 1 ? (
                                <IoMdHome className="primary-text" />
                              ) : location.location_type_id == 2 ? (
                                <MdWork className="primary-text" />
                              ) : (
                                <IoLocationSharp />
                              )}
                            </div>
                          </div>
                          <div className="col-9">
                            <p className="m-0 ps-3 ff-poppins-bold">
                              {location.location_type}{" "}
                            </p>
                            <p className="m-0 ps-3 ff-poppins-regular fs-12">
                              {location.address}
                            </p>
                          </div>
                          <div className="col-1">
                            <IoIosArrowForward />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div onClick={() => { navigate("/AddDeliveryAddress", { state: { fromPage: 'products' } }); }} >
                  <div className="d-flex align-items-center mt-4">
                    <div className="col-2">
                      <div className="w-100  d-flex justify-content-center fs-24 primary-text py-2">
                        <FaRegSquarePlus />
                      </div>
                    </div>
                    <div className="col-9">
                      <p className="m-0 ps-3 ff-poppins-bold primary-text">Add New Address</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            {
              addonModel == false ?
                (shipingtype == "2" && productDetail.delivery_location_status == true && (productDetail?.delivery_slot_type == '2' || productDetail?.delivery_slot_status != '0')) || shipingtype == "1" || flow == 'LoyaltyProduct' ? (
                  <div className="forgetRadiusDivRP"
                    // style={{ width: '-webkit-fill-available', marginBottom: 30, display: 'block', zIndex: 9999,position:'sticky',padding:10 }}
                    style={{ position: 'sticky', bottom: 0, marginBottom: 30, position: 'sticky', padding: 10 }}>
                    <div className="downContentDivHeadRP">
                      <textarea className="textAreaRP ff-poppins-regular fs-14 mb-1" placeholder="Add notes and instructions if any" value={consulation} onChange={(e) => setConsulation(e.target.value)} ></textarea>
                      {flow == 'LoyaltyProduct' || (flowtype == "EDIT_ORDER" && productDetail?.edit_order_datails?.refund_status == 1) ?
                        <div className="primary-text fs-10 ff-poppins-semiBold">
                         Rs {Math.abs(productDetail?.total_amount - JSON.parse(productDetail?.edit_order_datails?.paid_amount))}.00 willbe refunded directly to your payment method.
                        </div>
                        : selectpaytype == 1 ? (
                          <div className="toPayTextRP" style={{}}>
                            <span className="ff-poppins-semiBold">To Pay</span>
                            <b className="ms-2 fw-bold text-dark">
                              Rs <span>{

                                flowtype == "EDIT_ORDER" && productDetail?.edit_order_datails?.pay_status == "1" && productDetail?.edit_order_datails?.refund_status == 0
                                  ?
                                  <span> {productDetail?.total_amount - JSON.parse(productDetail?.edit_order_datails?.paid_amount)}.00 </span> :
                                  <span>{productDetail.total_amount}</span>
                              }     </span>
                            </b>
                          </div>
                        ) : selectpaytype == 2 ? (
                          <div className="toPayTextRP">
                            <span className="ff-poppins-semiBold">To Pay</span>
                            <b className="ms-2 fw-bold text-dark">
                              Rs <span>{productDetail?.payment_options?.advance_amount}</span>
                            </b>
                          </div>
                        ) : (
                          <></>
                        )}


                      <button style={{ borderRadius: "6px", margin: "15px 0px" }}
                        className={token ? "sendPayLinkBtnRP primary-bg ff-poppins-semiBold" : "border sendPayLinkBtnRP rimary-bg-1 ff-poppins-semiBold secondary-text-2"
                        } onClick={() => { handleConfirmPurchase() }} disabled={token && !isLoading ? false : true} >
                        {isLoading ? (
                          <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
                            <span className="visually-hidden">Loading...</span>
                          </>
                        ) : (
                          "Confirm purchase")}
                      </button>

                    </div>

                  </div>
                ) : (
                  ""
                ) : ""
            }

            {showDatePickerModal && (
              <DeliveryTime_Slot
                isOpen={isDatePickerOffCanvasOpen}
                toggleOffCanvas={toggleDatePickerOffCanvas}
              />
            )}
          </div >
          :
          <div className=" ReactLoading">
            <ReactLoading type={"spinningBubbles"} color={"#4E26A3"} />
          </div>
      }

      <div class="modal fade" id="exampleModaldelete" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-dialog-centered mainModal ">
          <div class="modal-content p-3">
            <div class="modal-body text-center ff-balooPaaji2-bold mb-3">
              <h5>Delete Product</h5>
              Are you sure want to delete the product ?
              <div className="d-flex justify-content-around mt-2">
                <button type="button" class="btn btn-secondary ff-balooPaaji2-bold" data-bs-dismiss="modal" > Cancel </button>
                <button type="button" class="btn btn-danger ff-balooPaaji2-bold" data-bs-dismiss="modal" onClick={() => deleteProduct()} >
                  Delete
                </button>
              </div>
            </div>
            <div class="modal Footer"></div>
          </div>
        </div>
      </div>

      <div className="modal fade" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={paymentSuccessModalRef} style={{ backgroundColor: "#fff", zIndex: "6666" }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal-success ff-poppins-semiBold" style={{ width: '80%' }}>
            <div className="modal-header d-flex justify-content-center align-items-center">
              <Lottie animationData={SuccessLotie} loop={false} />
            </div>
            <div className="mt-1 text-center fw-bold primary-text">
              Payment Success.
            </div>
            {/* <button onClick={() => closePaymentSuccessModal()}>Close</button> */}
            {
              instantDelivery ?
                <div className="">
                  {
                    deliverypartnerDetails.length > 0 ?
                      <div>
                        <div class="d-flex justify-content-center" >
                          <div className="d-flex secondary-bg-1 rounded-3 col-8 p-2">
                            <img className="rounded-3 ms-1" src={deliverypartnerDetails?.image} width={'70px'} height={'70px'} />
                            <div className="ms-2">
                              <div className="ff-poppins-semiBold fs-14">{deliverypartnerDetails?.name}</div>
                              <div className="ff-poppins-medium fs-12 text-secondary">&#x2022;   {deliverypartnerDetails?.gender} </div>
                              <div className="ff-poppins-medium fs-12 text-secondary">&#x2022; {deliverypartnerDetails?.staff_id} </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn okBtnRP" onClick={handleOkClick}>
                            OK
                          </button>
                        </div>
                      </div> : ""
                  }
                  {
                    deliverystaffAccept == "3" ?
                      <div className="text-center">
                        <div className="modal-body text-center m-0 fw-bold">
                          No Delivery partner Was found
                        </div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <button className="retryDeliveryBtn rounded-pill ff-balooPaaji2-bold fs-14 p-2 col-3" onClick={() => ReassignOrder()}>Retry <span className="ms-1"><FaArrowRotateRight /></span> </button>
                        </div>
                        <p className="ff-balooPaaji2-bold fs-14 m-0  mt-1 mb-1">OR</p>
                        <div className="d-flex justify-content-around mb-2">
                          <button className="retryDeliveryBtn rounded-pill ff-balooPaaji2-bold fs-14 p-2 col-4" onClick={() => ChooseSlotDelivery()}>Slot Delivery</button>
                          <button className="retryDeliveryBtn rounded-pill ff-balooPaaji2-bold fs-14 p-2 col-4" onClick={() => opencancelOrderModal()}>Cancel Order</button>
                        </div>
                        <p className="text-danger m-0 ff-poppins-regular fs-12 mt-2"> Cancel the order with 100% refund</p>
                      </div>
                      :
                      <div>
                        <div className="modal-body text-center fw-bold">
                          Please Wait ,Searching for delivery partner
                        </div>
                        <div class="d-flex justify-content-center mb-3">
                          <div class="  spinner-border primary-text  " role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </div>

                  }
                </div> :
                <div>
                  <div className="modal-body text-center fw-bold">
                    Redirecting you to your ticket.
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn okBtnRP" onClick={() => handleOkClick("normal")}>
                      OK
                    </button>
                  </div>
                </div>
            }
          </div>
        </div>
      </div>

      <div className={`modal fade`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={orderSuccessModalRef} >
        <div className="modal-dialog m-0 modal-dialog-centered mainModal">
          <div className="modal-content modal-success" style={{ width: "80%" }} >
            <div className="modal-header">
              <div>
                <div>
                  <div>
                    <Lottie animationData={SuccessLotie} loop={false} />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body modal-bodyRP">
              <div className="text-center fw-bold primary-text">
                Order Successful.
              </div>
              <div className="text-center fw-bold">
                Redirecting you to your ticket.
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn okBtnRP" onClick={() => handleOkClick("loyalty&cashafter")} >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={`modal fade`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={cancelOrderModalRef} style={{ zIndex: "9999", backgroundColor: "rgb(210 210 210)" }}>
        <div className="modal-dialog m-0 modal-dialog-centered mainModal">
          <div className="modal-content modal-success" style={{ width: "60%" }} >
            <div className="modal-header">
              <div className="text-center ff-poppins-regular fs-12">Are you sure you want to cancel the order</div>
            </div>
            <div className="d-flex justify-content-around  mt-3 mb-2">
              <button type="button" className="btn btn-secondary" onClick={() => closecancelOrderModal()}>Cancel</button>
              <button type="button" className="btn btn-danger" onClick={() => cancelConfirmedOrder()}>Confirm</button>
            </div>

          </div>
        </div>
      </div>
      {/* <div className={`modal fade`} ref={cancelOrderModalRef}  tabIndex="-1" aria-labelledby="exampleModalLabel" aria-modal="true">
        <div class="modal-dialog modal-dialog-centered"  >
          <div className="modal-body ps-5 pe-5 pt-4 pb-4">
            <div className="text-center ff-poppins-semiBold fs-16 mb-3">Delete account</div>  </div>
        </div>
      </div> */}
    </>
  );
};

export default ReviewPay;
