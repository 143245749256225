import { useState, useRef } from 'react';
import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import { singleCartDetails } from '../../Services/api';
import { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import p_down_arrow from '../../Assets/Images/p_down_arrow.svg';
import p_up_arrow from '../../Assets/Images/p_up_arrow.svg';
import PayNowOffCanvas from '../../Components/offCanvas/payNowOffCanvas';
import { IoIosArrowForward } from "react-icons/io";
import { MdWork } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { CgCloseO } from "react-icons/cg";
import { toast } from "react-toastify";
import { FaRegSquarePlus } from "react-icons/fa6";
import axiosInstance from "../../Services/axios";
import ReactLoading from "react-loading";
import Lottie from "lottie-react";
import SuccessLotie from "./../../Assets/lottieFiles/success.json";
import { getDatabase, ref, onValue, Database } from "firebase/database";

const ReviewAndPay = () => {
    const locationModalRef = useRef(null);
    const doorstepReqModalRef = useRef(null);
    const paymentSuccessModalRef = useRef(null);

    const navigate = useNavigate(); // Navigation hook
    const valueParam = useParams(); // URL parameter hook

    const location = useLocation();
    const { fromPage } = location.state || {};

    const [showMoreInforUser, setShowMoreInforUser] = useState(false);
    const [showMoreInforStaff, setShowMoreInforStaff] = useState(false);
    const [showMoreInforBillingInfo, setShowMoreInforBillingInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [cartDetail, setCartDetail] = useState('');
    const [isLocationListOffCanvasOpen, setLocationListOffCanvasOpen] = useState(false);
    const [isPayNowOffCanvasOpen, setPayNowOffCanvasOpen] = useState(true);
    const [selectedPaymentOption, setSelectedPaymentOption] = useState('1');
    const [amountToPay, setAmountToPay] = useState('');
    const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);
    const [userLocation, setUserLocation] = useState([]);
    const [locationLoading, setLocationLoading] = useState(false);
    const [offerServiceRedeemStatus, setofferServiceRedeemStatus] = useState()
    const [loyaltyPoints, setLoyaltyPoints] = useState()
    const [booking_ID, setBooking_ID] = useState('')
    const handlePaySuccessClose = () => setShowPaymentSuccessModal(false);
    const handlePaySuccessShow = () => setShowPaymentSuccessModal(true);



    // Fetch vendor staff list on component mount
    useEffect(() => {
        getSingleCartDetails();
        let localServiceDetail = localStorage.getItem('serviceDetail') ? JSON.parse(localStorage.getItem('serviceDetail')) : '';
        let offer_redeem_status = localServiceDetail.offer_status ? localServiceDetail.offer_status : '';
        let loyalty_redeem_points = localServiceDetail.loyalty_points ? localServiceDetail.loyalty_points : '';
        setLoyaltyPoints(loyalty_redeem_points)
        setofferServiceRedeemStatus(offer_redeem_status)
    }, []);

    // Function to fetch vendor staff list
    function getSingleCartDetails() {
        let localServiceDetail = localStorage.getItem('serviceDetail') ? JSON.parse(localStorage.getItem('serviceDetail')) : '';

        let param = {
            "vendor_id": valueParam.vendor_id,
            "cart_app_id": localServiceDetail.cart_app_id,
            "service_type": localServiceDetail?.serviceType ? localServiceDetail?.serviceType : '',
        };

        singleCartDetails(param).then((res) => {
           

            setCartDetail(res.data);
        }).catch((err) => {
            if (err.response.status == 400) {
                toast(err.response, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'text-danger toast-style-1'
                });
            }
            if (err.response.status === 500) {
                toast(err.response, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'text-danger toast-style-1'
                });
            }
        });
    }

    useEffect(() => {
        if (fromPage === 'addAddress') {
            getuserlocationlist()
        }
    }, []);

    // Function to toggle review and confirm offcanvas visibility
    const toggleLocationListOffCanvas = () => {
        setLocationListOffCanvasOpen(!isLocationListOffCanvasOpen);
    };

    const togglePayNowOffCanvas = () => {
        // setPayNowOffCanvasOpen(!isPayNowOffCanvasOpen);
        handlePaySuccessShow();
    };

    const handleOptionChange = (event) => {
        setSelectedPaymentOption(event.target.value);
        if (event.target.value === '1') {
            setAmountToPay(cartDetail.payment_details?.total_amount)
        }
    };

    const userLocations = () => {
        setLocationLoading(true)
        getuserlocationlist()
    }

    const openLocationModal = () => {
        locationModalRef.current.classList.add("show");
        locationModalRef.current.style.display = "block";
    };

    const closeLocationModal = () => {
        locationModalRef.current.classList.remove("show");
    };

    const openDoorReqModal = () => {
        doorstepReqModalRef.current.classList.add("show");
        doorstepReqModalRef.current.style.display = "block";
    };

    const closeDoorReqModal = () => {
        doorstepReqModalRef.current.classList.remove("show");
    };

    const openPaySuccessModal = () => {
        paymentSuccessModalRef.current.classList.add("show");
        paymentSuccessModalRef.current.style.display = "block";
    };

    const closePaySuccessModal = () => {
        paymentSuccessModalRef.current.classList.remove("show");
    };

    const getuserlocationlist = () => {
        let localServiceDetail = localStorage.getItem('serviceDetail') ? JSON.parse(localStorage.getItem('serviceDetail')) : '';
        let vendor_id = localServiceDetail.vendorId ? localServiceDetail.vendorId : '';
        let cart_id = localServiceDetail.cart_app_id ? localServiceDetail.cart_app_id : '';
        let service_type = localServiceDetail.serviceType ? localServiceDetail.serviceType : '';

        try {
            let reqData = {
                vendor_id: vendor_id,
                cart_app_id: cart_id,
                service_type: service_type,
            };
            axiosInstance
                .post("user/user_locations_list", reqData)
                .then((res) => {
                    setUserLocation(res.data.saved_locations);
                    openLocationModal()
                    setLocationLoading(false)
                }).catch((err) => {
                    if (err.response.status === 400) {
                        alert(`Error:${err.response.state}-${err.response.message}`);
                    }
                    if (err.response.status === 500) {
                        alert(`Error:${err.response.status}-${err.response.message}`);
                    }
                });
        } catch { }
    };

    const select_Location = (latlng) => {
        try {
            let localServiceDetail = localStorage.getItem('serviceDetail') ? JSON.parse(localStorage.getItem('serviceDetail')) : '';
            let vendor_id = localServiceDetail.vendorId ? localServiceDetail.vendorId : '';
            let cart_id = localServiceDetail.cart_app_id ? localServiceDetail.cart_app_id : '';
            let service_type = localServiceDetail.serviceType ? localServiceDetail.serviceType : '';

            let reqData = {
                "vendor_id": vendor_id,
                "cart_app_id": cart_id,
                "service_type": service_type,
                "location": latlng
            };
            axiosInstance.post("user/cart_select_location", reqData)
                .then((res) => {

                    closeLocationModal();
                    getSingleCartDetails()
                    // setConfirmlocation(1);
                    // getselectProductDetail();
                })
                .catch((err) => {
                    if (err.response.status === 400) {
                        alert(`Error:${err.response.state}-${err.response.message}`);
                    }
                    if (err.response.status === 500) {
                        alert(`Error:${err.response.status}-${err.response.message}`);
                    }
                });
        } catch (error) {

            alert("An error occurred. Please try again.");
        }
    };

    const sent_doorstep_service_request = async () => {
        setIsLoading(true);
        let localServiceDetail = localStorage.getItem('serviceDetail') ? JSON.parse(localStorage.getItem('serviceDetail')) : '';
        let vendor_id = localServiceDetail.vendorId ? localServiceDetail.vendorId : '';
        let cart_id = localServiceDetail.cart_app_id ? localServiceDetail.cart_app_id : '';
        let service_type = localServiceDetail.serviceType ? localServiceDetail.serviceType : '';

        let data = {
            cart_app_id: cart_id,
            service_type: service_type
        };
        await axiosInstance
            .post("user/sent_doorstep_service_request", data)
            .then((res) => {
                if (res.status == 200) {

                    openDoorReqModal()
                    // setSendRequestModelONOFF(true)
                    // setSendRequestBtnLoader(false)
                }
            })
            .catch((err) => {
                if (err.response.status == 400) {
                    toast(err.response, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'text-danger toast-style-1'
                    });
                }
                if (err.response.status === 500) {
                    toast(err.response, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'text-danger toast-style-1'
                    });
                }
            });
    }
 

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>Review & Pay</div>
                </div>
            </div>

            <div className='reviewAndPay offcanvas-parentType2'>
                <div className='scroller-style-y'>
                    <div className='bg-white rounded-2 p-3'>
                        {
                            cartDetail.booking_service_type == '2' &&
                            <>
                                <div className='ff-poppins-semiBold fs-14'>
                                    Service Address
                                </div>
                                {cartDetail?.service_location_status ?
                                    <div onClick={() => userLocations()}>
                                        <div className="d-flex align-items-center border p-1 mt-4" style={{
                                            opacity:
                                                cartDetail?.service_location?.select_status == 0 ? 0.4 : 1,
                                        }}>
                                            <div className="col-2">
                                                <div className="border rounded w-100 border-black border-opacity-10 rounded d-flex justify-content-center fs-24 primary-text py-2">
                                                    {cartDetail?.service_location?.location_type_id == 1 ? (
                                                        <IoMdHome className="primary-text" />
                                                    ) : cartDetail?.service_location?.location_type_id == 2 ? (
                                                        <MdWork className="primary-text" />
                                                    ) : (
                                                        <IoLocationSharp />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-9">
                                                <p className="m-0 ps-3 ff-poppins-bold">
                                                    {cartDetail?.service_location?.location_type}{" "}
                                                </p>
                                                <p className="m-0 ps-3 ff-poppins-regular singleline-text fs-12">
                                                    {cartDetail?.service_location?.address}
                                                </p>
                                                <p className='m-0 ps-3 ff-poppins-regular fs-12'>
                                                    {cartDetail?.service_location?.directions?.distance.text} - {cartDetail?.service_location?.directions?.duration.text}
                                                </p>
                                            </div>
                                            <div className="col-1">
                                                <IoIosArrowForward />
                                            </div>
                                        </div>
                                    </div>
                                    : <button type='button' onClick={() => userLocations()} className={`btn-type-3 ff-poppins-medium fs-12 w-100 border bg-white text-dark mt-3 mb-3 text-center justify-content-center d-flex align-items-center`}>
                                        {!locationLoading ? 'Add Address' : <ReactLoading type={"spinningBubbles"} color={"#a1a1a1"} height={20} width={20} />}
                                    </button>
                                }
                            </>
                        }


                        <div className='ff-poppins-semiBold fs-12 mt-3 mb-3 pb-3'>
                            {cartDetail.book_date_time}
                        </div>

                        <div>
                            <div className='ff-poppins-semiBold fs-12'>
                                Booking For
                            </div>
                            <div className='profileCard'>
                                <div className='d-flex'>
                                    <img className='rounded-2' width={'56px'} height={'54px'} src={cartDetail.booking_for?.image} />
                                    <div className='ms-3 w-100'>
                                        <div className='ff-poppins-semiBold fs-14'>
                                            {cartDetail.booking_for?.name}
                                        </div>
                                        <div className='ff-poppins-regular fs-10'>
                                            {cartDetail.booking_for?.relationship}
                                        </div>
                                        <div className='ff-poppins-regular fs-10'>
                                            {cartDetail.booking_for?.category_name}

                                        </div>
                                        {/* <div className='ff-poppins-regular fs-10'>
                                            {cartDetail.booking_for?.name} - {cartDetail.booking_for?.breed}
                                        </div> */}
                                        {/* <div className='ff-poppins-regular fs-10'>
                                        {cartDetail.booking_for?.name} - {cartDetail.booking_for?.breed}
                                    </div> */}
                                        {
                                            cartDetail?.vendor_details?.vendor_type_id == '2' ?
                                                <div className='ff-poppins-regular fs-10'>
                                                    {cartDetail.booking_for?.type} - {cartDetail.booking_for?.breed}
                                                </div> :
                                                <></>
                                        }
                                        {
                                            showMoreInforUser
                                                ?
                                                <div>
                                                    {
                                                        cartDetail?.vendor_details?.vendor_type_id == '2' ?
                                                            <div className='ff-poppins-regular fs-10 mb-1'>
                                                                {cartDetail.booking_for?.gender} - {cartDetail.booking_for?.age} Years - {cartDetail.booking_for?.weight} Kg
                                                            </div> : <></>
                                                    }

                                                    <div className='ff-poppins-regular fs-10 '>
                                                        {cartDetail.booking_for?.register_number}
                                                    </div>
                                                    <hr className='mt-1 mb-1' />
                                                    <div className='ff-poppins-regular text-center col-3 rounded-1 ff-poppins-regular temperamentBox fs-10 '>
                                                        {cartDetail.booking_for?.temperament}
                                                    </div>
                                                    <div className='ff-poppins-regular fs-10 '>
                                                        {cartDetail.booking_for?.about}
                                                    </div>

                                                    <div className='ff-poppins-semiBold fs-10 d-flex align-items-center primary-text' role='button' onClick={() => { setShowMoreInforUser(!showMoreInforUser) }}>
                                                        Less info
                                                        <img
                                                            src={p_up_arrow}
                                                            width={'10px'}
                                                            height={'7px'}
                                                            alt='Show Less Info'
                                                            role='button'
                                                            className='ms-1'
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <div className='ff-poppins-semiBold fs-10 d-flex align-items-center primary-text' role='button' onClick={() => { setShowMoreInforUser(!showMoreInforUser) }}>
                                                    More info
                                                    <img
                                                        src={p_down_arrow}
                                                        width={'10px'}
                                                        height={'7px'}
                                                        alt='Show Less Info'
                                                        role='button'
                                                        className='ms-1'
                                                    />
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            cartDetail?.vendor_details?.staff_app_status == "1" && cartDetail?.booking_staff?.staff_id != "1" ?
                                <div className='mt-4'>
                                    <div className='ff-poppins-semiBold fs-12'>
                                        Appointment with
                                    </div>
                                    <div className='profileCard'>
                                        <div className='d-flex'>
                                            <img className='rounded-2' width={'56px'} height={'54px'} src={cartDetail.booking_staff?.staff_image} />
                                            <div className='ms-3 w-100'>
                                                <div className='ff-poppins-semiBold fs-14'>
                                                    {cartDetail.booking_staff?.staff_name}
                                                </div>
                                                <div className='ff-poppins-regular fs-12'>
                                                    {cartDetail.booking_staff?.staff_designation}
                                                </div>t
                                                {
                                                    showMoreInforStaff
                                                        ?
                                                        <div>
                                                            <div className='ff-poppins-regular fs-12 mb-1'>
                                                                {cartDetail.booking_staff?.staff_specialist}
                                                            </div>
                                                            <hr className='mt-1 mb-1' />
                                                            <div className='ff-poppins-regular fs-12 '>
                                                                {cartDetail.booking_staff?.staff_about}
                                                            </div>
                                                            <div className='ff-poppins-semiBold fs-12 d-flex align-items-center primary-text' role='button' onClick={() => { setShowMoreInforStaff(!showMoreInforStaff) }}>
                                                                Less info
                                                                <img
                                                                    src={p_up_arrow}
                                                                    width={'10px'}
                                                                    height={'7px'}
                                                                    alt='Show Less Info'
                                                                    role='button'
                                                                    className='ms-1'
                                                                />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='ff-poppins-semiBold fs-12 d-flex align-items-center primary-text' role='button' onClick={() => { setShowMoreInforStaff(!showMoreInforStaff) }}>
                                                            More info
                                                            <img
                                                                src={p_down_arrow}
                                                                width={'10px'}
                                                                height={'7px'}
                                                                alt='Show Less Info'
                                                                role='button'
                                                                className='ms-1'
                                                            />
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <></>
                        }





                        {cartDetail && cartDetail.payment_options && (Number(cartDetail.booking_service_type) == 1 || (Number(cartDetail.booking_service_type) == 2 && (Number(cartDetail.doorstep_request_status) == 2))) && offerServiceRedeemStatus == '' ?

                            // {cartDetail && cartDetail.payment_options && (Number(cartDetail.booking_service_type) == 1 || (Number(cartDetail.booking_service_type) == 2 && (Number(cartDetail.doorstep_request_status) == 2))) ?

                            <div className='mt-4'>
                                <div className='ff-poppins-semiBold fs-14'>Payment method</div>
                                {
                                    cartDetail.payment_options?.online_payment == 1 &&
                                    <div class="d-flex justify-content-between align-items-center mt-2">
                                        <label class="form-check-label w-100 d-flex justify-content-between align-items-center" role='button' for="flexRadioDefault1">
                                            <div>
                                                <div className='ff-poppins-semiBold fs-12'>Pay Full </div>
                                                <div className='ff-poppins-regular fs-10'>Pay total amount now  </div>
                                            </div>

                                            <input
                                                class="form-check-input custom-radio1"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="flexRadioDefault1"
                                                value="1"
                                                checked={selectedPaymentOption === "1"}
                                                onChange={handleOptionChange}
                                            />

                                            <span class="radio-icon"></span>
                                        </label>
                                    </div>
                                }

                                {
                                    cartDetail.payment_options?.advance_payment == 1 &&
                                    <div class="d-flex justify-content-between align-items-center mt-2">
                                        <label class="form-check-label w-100 d-flex justify-content-between align-items-center" role='button' for="flexRadioDefault2">
                                            <div>
                                                <div className='ff-poppins-semiBold fs-12'>Pay advance</div>
                                                <div className='ff-poppins-regular fs-10'>Pay a merchant-determined amount upfront now.</div>
                                            </div>

                                            <input
                                                class="form-check-input custom-radio1"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="flexRadioDefault2"
                                                value="2"
                                                checked={selectedPaymentOption === "2"}
                                                onChange={handleOptionChange}
                                            />

                                            <span class="radio-icon"></span>
                                        </label>
                                    </div>
                                }

                                {
                                    cartDetail.payment_options?.cash_after_service == 1 &&
                                    <div class="d-flex justify-content-between align-items-center mt-2">
                                        <label class="form-check-label w-100 d-flex justify-content-between align-items-center" role='button' for="flexRadioDefault3">
                                            <div>
                                                <div className='ff-poppins-semiBold fs-12'>Cash after service</div>
                                                <div className='ff-poppins-regular fs-10'>Pay in cash at the store after service completion.</div>
                                            </div>

                                            <input
                                                class="form-check-input custom-radio1"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="flexRadioDefault3"
                                                value="3"
                                                checked={selectedPaymentOption === "3"}
                                                onChange={handleOptionChange}
                                            />

                                            <span class="radio-icon"></span>
                                        </label>
                                    </div>
                                }

                            </div>
                            : <></>
                        }

                        {
                            showMoreInforBillingInfo
                                ?
                                <div
                                    className='border-bottom mt-3 pt-3' role='button'
                                    onClick={() => { setShowMoreInforBillingInfo(!showMoreInforBillingInfo) }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='ff-poppins-semiBold fs-14 mb-2 primary-text'>
                                            BILLING INFORMATION
                                        </div>
                                        <img src={p_up_arrow}
                                            width={'13px'}
                                            height={'7px'}
                                            alt='Show more Info'
                                            role='button' />
                                    </div>

                                    <div className='ff-poppins-regular  fs-12'>
                                        Booking Initiated at {cartDetail.book_initiate_time} by {cartDetail.booking_by} for {cartDetail.booking_for?.name}
                                    </div>
                                </div>
                                :
                                <div role='button' className='d-flex align-items-center justify-content-between border-bottom mt-3 pt-3'
                                    onClick={() => { setShowMoreInforBillingInfo(!showMoreInforBillingInfo) }}>
                                    <div className='ff-poppins-semiBold primary-text fs-14 mb-2'>
                                        BILLING INFORMATION
                                    </div>
                                    <img src={p_down_arrow}
                                        width={'13px'}
                                        height={'7px'}
                                        alt='Show more Info'
                                        role='button' />
                                </div>
                        }


                        {
                            cartDetail?.card_applied_status && cartDetail?.applied_card_detail?.card_type && cartDetail?.applied_card_detail?.offer_type == 3 ?
                                <>
                                    <div className='ff-poppins-semiBold primary-text fs-14 mt-2 pt-1 pb-1'>
                                        Buy Services
                                    </div>
                                    <div>
                                        {
                                            cartDetail && cartDetail.offer_details.buy_services && cartDetail.offer_details.buy_services.length > 0
                                                ? cartDetail.offer_details.buy_services.map(item => {
                                                    return (
                                                        <div className='d-flex align-items-center justify-content-between ff-poppins-regular fs-13 service-item'>
                                                            <span>{item.service_name}</span>
                                                            <span>RS {item.service_price}</span>
                                                        </div>
                                                    )
                                                }) : <></>
                                        }
                                    </div>
                                    <div className='charges pb-0' style={{}}>
                                        <div className='d-flex align-items-center justify-content-between ff-poppins-regular primary-text fs-13 service-item'>
                                            <span>Total(Offer Price)</span>
                                            <span>RS {cartDetail.offer_details.buy_services_total ? cartDetail.offer_details.buy_services_total : '0.00'}</span>
                                        </div>
                                    </div>

                                    <div className='ff-poppins-semiBold primary-text fs-14 mt-2 pt-1 pb-1'>
                                        Get Services
                                    </div>
                                    <div>
                                        {
                                            cartDetail && cartDetail.offer_details.get_services && cartDetail.offer_details.get_services.length > 0
                                                ? cartDetail.offer_details.get_services.map(item => {
                                                    return (
                                                        <div className='d-flex align-items-center justify-content-between ff-poppins-regular fs-13 service-item'>
                                                            <span>{item.service_name}</span>
                                                            <span>RS {item.service_price}</span>
                                                        </div>
                                                    )
                                                }) : <></>
                                        }
                                    </div>
                                    <div className='charges pb-0' style={{ borderBottom: 0 }}>
                                        <div className='d-flex align-items-center justify-content-between ff-poppins-regular primary-text fs-13 service-item'>
                                            <span>Total(Free)</span>
                                            <span>RS {cartDetail.offer_details.get_services_total ? cartDetail.offer_details.get_services_total : '0.00'}</span>
                                        </div>
                                    </div>

                                </> :
                                <>
                                    <div className='ff-poppins-semiBold primary-text fs-14 mt-2 pt-1 pb-1'>
                                        Services
                                    </div>
                                    <div>
                                        {
                                            cartDetail.cart_services?.map(item => {
                                                return (
                                                    <div className='d-flex align-items-center justify-content-between ff-poppins-regular fs-12 service-item'>
                                                        <span>{item.service_name}</span>
                                                        <span>RS {item.service_price}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        cartDetail && cartDetail.discount_status == 1 ?
                                            <div className='charges pb-0' style={{ borderBottom: 0 }}>
                                                <div className='d-flex align-items-center justify-content-between ff-poppins-regular text-success fs-13 service-item'>
                                                    <span>{cartDetail?.discount_detail?.lable}</span>
                                                    <span>RS {cartDetail?.discount_detail?.discount_amount}</span>
                                                </div></div>
                                            : <></>
                                    }
                                </>}

                        {/* <button onClick={() => redeem_loyalty()}>Loyalty offer </button> */}

                        <div className='charges'>
                            <div className='d-flex align-items-center justify-content-between ff-poppins-regular  fs-13 service-item'>
                                <span>Sub Total ({cartDetail.payment_details?.GST_type})</span>
                                <span>{cartDetail.payment_details?.net_amount}</span>
                            </div>
                            <div className='d-flex align-items-center justify-content-between ff-poppins-regular  fs-13 service-item'>
                                <span>Platform charges</span>
                                <span>{cartDetail.payment_details?.user_platform_charges}</span>
                            </div>
                            {
                                offerServiceRedeemStatus == '1' ?
                                    <div className='d-flex align-items-center justify-content-between ff-poppins-regular fs-13 service-item'>
                                        <span>Pingle points redeemed ({loyaltyPoints})</span>
                                        <span>- {cartDetail.payment_details?.net_amount}</span>
                                    </div> :
                                    <div>
                                        <div className='d-flex align-items-center justify-content-between ff-poppins-regular fs-13 service-item'>
                                            <span>SGST {cartDetail.payment_details?.SGST_percentage}</span>
                                            <span>{cartDetail.payment_details?.SGST_amount}</span>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between ff-poppins-regular  fs-13 service-item'>
                                            <span>CGST {cartDetail.payment_details?.CGST_percentage}</span>
                                            <span>{cartDetail.payment_details?.CGST_amount}</span>
                                        </div>
                                    </div>
                            }

                            {
                                cartDetail.payment_details?.round_of != 0 ?
                                    <div className='d-flex align-items-center justify-content-between ff-poppins-regular  fs-10 service-item'>
                                        <span>Round Off</span>
                                        <span>{cartDetail.payment_details?.round_of}</span>
                                    </div> : <></>
                            }

                        </div>

                        {
                            offerServiceRedeemStatus == '' ?
                                <div className='ff-poppins-bold text-end fs-14 mt-3 pt-1'>
                                    Total - Rs. {amountToPay ? amountToPay : cartDetail.payment_details?.total_amount}
                                </div> : <></>
                        }


                        {
                            selectedPaymentOption == "2" ?
                                <div>
                                    <div className='d-flex justify-content-end ff-poppins-bold fs-14 m-0 primary-text'>
                                        <p className='m-0'>Advance amount  - <span>Rs {cartDetail?.payment_options?.advance_amount}</span></p>
                                    </div>
                                    <div className='d-flex justify-content-end ff-poppins-bold fs-14 m-0 primary-text'>
                                        <p className='m-0'>Balance amount  -   <span>Rs {cartDetail?.payment_options?.balence_amount}</span> </p>
                                    </div>
                                </div> : <></>
                        }

                    </div>
                    {
                        cartDetail && (Number(cartDetail.booking_service_type)) == 1 || (Number(cartDetail.booking_service_type) == 2 && (Number(cartDetail.doorstep_request_status) == 2)) ?
                            <PayNowOffCanvas
                                isOpen={isPayNowOffCanvasOpen}
                                toggleOffCanvas={openPaySuccessModal}
                                paymentMethod={selectedPaymentOption}
                                payAmount={selectedPaymentOption == '1' ? cartDetail.payment_details?.total_amount : cartDetail?.payment_options?.advance_amount}
                                getbookingId={(value) => setBooking_ID(value)}
                            /> :
                            <div className={`px-2 py-4`} style={{ width: '100%', backgroundColor: '#fff', borderTopLeftRadius: 20, borderTopRightRadius: 20, position: 'sticky', bottom: 0, marginTop: 10 }}>
                                <div className="offcanvas-body small">
                                    {
                                        cartDetail && (Number(cartDetail.booking_service_type)) == 1 || (Number(cartDetail.booking_service_type) == 2 && (Number(cartDetail.doorstep_request_status) == 0)) ?
                                            <>
                                                {/* <div className='toPay'>
                                                <span className='ff-poppins-regular fs-12 primary-text'>To Pay </span>
                                                <span className='ff-poppins-bold fs-12'>RS {cartDetail.payment_details?.total_amount}</span>
                                            </div> */}

                                                <button
                                                    type='button'
                                                    disabled={cartDetail?.service_location_status === 0 || isLoading}
                                                    className={`btn-type-1 ff-poppins-semiBold fs-14 w-100 ${cartDetail?.service_location_status === 0 ? "secondary-bg text-dark" : 'primary-bg text-white'}  border-0  payNowBtn`}
                                                    onClick={() => sent_doorstep_service_request()}
                                                    style={{ position: 'relative' }} // Set position relative for button
                                                >
                                                    {isLoading && (
                                                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                            <ReactLoading type={'spinningBubbles'} color={'#fff'} height={'20px'} width={'20px'} />
                                                        </div>
                                                    )}
                                                    {!isLoading && 'Send Request'}
                                                </button>
                                            </>
                                            :
                                            <>
                                                {/* <div className='toPay'>
                                                <span className='ff-poppins-regular fs-12 primary-text'>To Pay </span>
                                                <span className='ff-poppins-bold fs-12'>RS {cartDetail.payment_details?.total_amount}</span>
                                            </div> */}

                                                <div className='row m-0'>
                                                    <div className='col-6 p-2'>
                                                        {/* <button type='button'
                                                            className='btn-type-1 ff-poppins-semiBold fs-14 w-100 primary-bg border-0 text-white payNowBtn'
                                                            onClick={() => ""}
                                                        >
                                                            Call
                                                        </button> */}
                                                        <a href={`tel:${cartDetail?.vendor_details?.vendor_mbl}`} className='text-decoration-none'>
                                                            <button type='button' className='btn-type-1 ff-poppins-semiBold fs-14 w-100 primary-bg border-0 text-white payNowBtn'  >
                                                                Call
                                                            </button>
                                                        </a>


                                                    </div>
                                                    <div className='col-6 p-2'>
                                                        <button type='button'
                                                            className='btn-type-1 ff-poppins-semiBold fs-14 w-100 primary-bg border-0 text-white payNowBtn'
                                                            onClick={() => navigate(`/chat/${cartDetail?.vendor_details?.vendor_id}`)}
                                                        >
                                                            Message
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                    }

                </div>



                {

                    <div className={`modal fade`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={doorstepReqModalRef} data-bs-backdrop="false" style={{ backgroundColor: "#fff" }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content modal-success" style={{ width: 300 }}>
                                <div className="modal-body justify-content-center ">
                                    <div>
                                        <Lottie animationData={SuccessLotie} loop={false} />
                                    </div>
                                    <div className="text-center fw-bold primary-text">
                                        Sent Request
                                    </div>
                                    <div className="text-center fw-normal">
                                        Your appointment request has been successfully sent please wait for the merchant acceptence.
                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <button type="button" className="btn okBtnRP primary-bg text-white rounded my-3" onClick={() => {
                                            localStorage.removeItem("serviceDetail");
                                            localStorage.removeItem("selectedServiceOption");
                                            localStorage.removeItem("appliedCard");
                                            closeDoorReqModal();
                                            // navigate('/', { replace: true })
                                            navigate("/")
                                        }} style={{ backgroundColor: '#4e26a3', color: 'initial' }}>
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    <div class="modal fade  " tabindex="-1" ref={paymentSuccessModalRef} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered m-0" role="document">
                            <div class="modal-content" style={{ width: 300 }}>
                                <div class="modal-body justify-content-center">
                                    <div>
                                        <Lottie animationData={SuccessLotie} loop={false} />
                                    </div>
                                    <div className="text-center ff-poppins-bold primary-text">
                                        Payment Success
                                    </div>
                                    <div className="text-center ff-poppins-semiBold fw-normal">
                                        Redirecting to the booking manger.
                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <button type="button" className="btn okBtnRP ff-poppins-bold primary-bg text-white rounded my-3" onClick={() => {
                                            localStorage.removeItem("serviceDetail");
                                            localStorage.removeItem("selectedServiceOption");
                                            localStorage.removeItem("appliedCard");
                                            closePaySuccessModal(); 
                                            
                                            navigate(`/details/${booking_ID}`, {state:{fromPage:"ReviewPay"}})
                                        }}
                                            style={{ backgroundColor: '#4e26a3', color: 'initial' }}>
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="offcanvas fade" tabIndex="-1"
                    ref={locationModalRef} style={{ maxHeight: '70vh', position: 'sticky', overflow: 'scroll' }}>
                    <div className="offcanvas-body small bg-light position-relative" >
                        <div className="d-flex justify-content-between">
                            <div className="ff-poppins-semiBold">
                                <h5>Choose address</h5>
                            </div>
                            <div onClick={() => closeLocationModal()}>
                                <CgCloseO />
                            </div>
                        </div>
                        <div>
                            {userLocation.map((location, index) => {
                                return (
                                    <div onClick={() => location.select_status ? select_Location(location.latlng) : ""} >
                                        <div className="d-flex align-items-center mt-4"
                                            style={{ opacity: location.select_status == 0 ? 0.4 : 1 }}  >
                                            <div className="col-2">
                                                <div className="border w-100 border-black border-opacity-10 rounded d-flex justify-content-center fs-24 primary-text py-2">
                                                    {location.location_type_id == 1 ? (
                                                        <IoMdHome className="primary-text" />
                                                    ) : location.location_type_id == 2 ? (
                                                        <MdWork className="primary-text" />
                                                    ) : (
                                                        <IoLocationSharp />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-9">
                                                <p className="m-0 ps-3 ff-poppins-bold">
                                                    {location.location_type}
                                                </p>
                                                <p className="m-0 ps-3 ff-poppins-regular fs-12">
                                                    {location.address}
                                                </p>
                                            </div>
                                            <div className="col-1">
                                                <IoIosArrowForward />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div onClick={() => { navigate("/AddDeliveryAddress", { state: { fromPage: 'service' } }); }} >
                            <div className="d-flex align-items-center mt-4">
                                <div className="col-2">
                                    <div className="w-100  d-flex justify-content-center fs-24 primary-text py-2">
                                        <FaRegSquarePlus />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <p className="m-0 ps-3 ff-poppins-bold primary-text">Add New Address</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >

            {showPaymentSuccessModal && (
                <div className="modal-backdrop show"></div>
            )
            }

            {
                showPaymentSuccessModal && (
                    <div className="modal justify-content-center" tabIndex="-1" style={{ display: 'block' }}>
                        <div className="modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='ff-poppins-semiBold fs-1 primary-text text-center'>
                                        Attention!
                                    </div>
                                    <div className='ff-poppins-regular fs-14 text-center mt-2'>
                                        To proceed further you need to login/register and get access to all the features.
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center mt-1 mb-1' role='button' onClick={() => navigate('/login')}>
                                        <div className='loginBtn primary-bg text-center text-white p-1 ff-poppins-medium fs-16'>Login</div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center' role='button' onClick={handlePaySuccessClose}>
                                        <div className='loginBtn text-center text-secondary p-1 ff-poppins-medium fs-16 secondary-bg-1 mt-2'>Close</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </>
    )
}

export default ReviewAndPay